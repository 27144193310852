.wrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 73px 0 90px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }

    &.visible {
        .services {
            animation: slideUp 1s forwards;
        }
    
        .buttonContainer {
            animation: slideUp 1s forwards;
            animation-delay: 0.4s;
        }
    }
}

.services {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 36px;
    opacity: 0;

    @media only screen and (max-width: 1300px) {
        flex-wrap: wrap;
    }
}

.service {
    width: 183px;
    text-align: center;

    @media only screen and (max-width: 1300px) {
        width: 33%;
        margin-bottom: 60px;
    }

    @media only screen and (max-width: 1100px) {
        width: 50%;
    }

    @media only screen and (max-width: 400px) {
        width: 100%;
    }   
}

.iconContainer {
    height: 100px;
    margin-bottom: 29px;
    position: relative;
}

.icon {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100px;
}

.title {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #080B0B;
    margin-bottom: 6px;

    @media only screen and (max-width: 650px) {
        font-size: 16px;
        line-height: 27px;
    }
}

.badges{
    display: flex;
    justify-content: center
}

.online {
    width: 72px;
    height: 23px;
    position: relative;
    text-align: center;
    color: #fff;
    line-height: 23px;
}

.online img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.offline {
    width: 72px;
    height: 23px;
    position: relative;
    text-align: center;
    color: #fff;
    line-height: 23px;
}

.offline img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.online + .offline {
    margin-left: 9px;
}

.buttonContainer {
    opacity: 0;
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}