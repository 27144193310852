.topButton {
    position: fixed;
    height: 85px;
    width: 85px;
    bottom: 108px;
    right: 48px;
    cursor: pointer;
    z-index: 20;
    transition: 0.4s;
    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);

    &.visible {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0)
    }
}

@media only screen and (max-width: 1100px) {
   .topButton{
       display: none;
    } 
}