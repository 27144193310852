.slider {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 36px;
}

.slide {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: center;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.iconContainer {
    height: 168px;
    width: 200px;
    position: relative;
    margin: 0 auto 52px;
    text-decoration: none;
    display: block;

    img {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        height: 100%;
        width: auto;
    }

    @media only screen and (max-width: 450px) {
        height: 102px;
        width: 120px;
    }
}

.serviceNum {
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 2px;

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #080B0B;
    margin-bottom: 32px;
    text-decoration: none;

    @media only screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 29px;
    }
}

.text {
    width: 1035px;
    margin: 0 auto 36px;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: rgba(8, 11, 11, 0.7);

    @media only screen and (max-width: 1050px) {
        width: 100%;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.nextArrow {
    color: rgba($color: #080B0B, $alpha: 0.1);
    font-size: 40px;
    transition: 0.3s;
    position: absolute;
    top: 250px;
    width: 36px;
    height: 40px;
    right: 12vw;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        color: rgba($color: #080B0B, $alpha: 0.3);
    }

    @media only screen and (max-width: 1300px) {
        right: 20px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.prevArrow {
    color: rgba($color: #080B0B, $alpha: 0.1);
    font-size: 40px;
    transition: 0.3s;
    position: absolute;
    position: absolute;
    top: 250px;
    width: 36px;
    height: 40px;
    left: 12vw;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        color: rgba($color: #080B0B, $alpha: 0.3);
    }

    @media only screen and (max-width: 1300px) {
        left: 20px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.details {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div:last-child {
        margin-right: 0;
    }
}

.price {
    padding-left: 35px;
    box-sizing: border-box;
    position: relative;
    line-height: 52px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;

        img {
            height: 32px;
        }
    }
}

.time {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 50px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;

        img {
            height: 32px;
        }
    }
}

.place {
    padding-left: 50px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;

        img {
            height: 32px;
        }
    }
}

.person {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;

        img {
            height: 32px;
        }
    }
}

:global{
    .services-slick-dots {
        width: 100%;
        height: 11px;
        position: absolute;
        bottom: -20px;
        margin: auto;
        left: 0;
        right: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        font-size: 0;

        li {
            width: 11px;
            height: 11px;
            display: inline-block;
            margin-right: 8px;
            width: 11px;
            height: 11px;
            background-image: url(../../../assets/img/dark-slider-dot.svg);
            transition: 0.3s;

            button {
                width: 11px;
                height: 11px;
                opacity: 0;
                cursor: pointer;
            }

            &.slick-active {
                background-image: url(../../../assets/img/red-slider-dot.svg);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}