.wrapper {
    width: 100%;
    padding-left: 748px;
    padding-top: 68px;
    padding-bottom: 76px;
    position: relative;
    box-sizing: border-box;

    @media only screen and (max-width: 1300px) {
        padding-left: 500px;
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 90px;
        padding-bottom: 90px;
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    width: 629px;
    height: 110%;

    @media only screen and (max-width: 1300px) {
        width: 440px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        left: 0;
        bottom: 366px;
        height: 480px;
        top: initial;
    }

    @media only screen and (max-width: 600px) {
        bottom: 296px;
    }
}

.title {
    font-size: 35px;
    line-height: 43px;
    color: #080B0B;
    margin-bottom: 35px;

    @media only screen and (max-width: 650px) {
        font-size: 23px;
        line-height: 28px;
    }
}

.adress {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 32px;

    p {

        margin-top: 0;
    }

    a {
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 1100px) {
        margin-bottom: 634px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 16px;
        line-height: 28px;
    }
}

.socialTitle{
    font-size: 35px;
    line-height: 43px;
    color: #080B0B;
    margin-bottom: 18px;   

    @media only screen and (max-width: 650px) {
        font-size: 23px;
        line-height: 28px;
    }
}

.socialContainer {
    width: 588px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1100px) {
        margin: 0 auto;
    }

    @media only screen and (max-width: 600px) {
        width: 300px;
        transform: translateX(-15px);
    }
}