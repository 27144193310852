@font-face {
    font-family: 'monsters';
    src: local('FiraSans'), url(../../assets/fonts/monsters.ttf) format('truetype');
}

.input {
    position: relative;
    width: 565px;
    height: 65px;
    margin-bottom: 24px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    input {
        position: absolute;
        width: 100%;
        height: 44px;
        top: 11px;
        left: 0;
        border: none;
        background: none;
        outline: none;
        color: #080B0B;
        font-family: 'monsters';
        padding-left: 25px;
        font-size: 24px;
        box-sizing: border-box;
        
        &::placeholder {
            color: rgba(8, 11, 11, 0.2);
        }
    }

    @media only screen and (max-width: 650px) {
        width: 336px;
        height: 49px;
        margin: 0 auto 18px;

        input {
            top: 4px;
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 360px) {
        width: 100%;
        height: 42px;

        input {
            top: 0;
        }
    }
}


