.methodology {
    padding-top: 150px;

    @media only screen and (max-width: 1100px) {
        padding-top: 50px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.hero {
     width: 100%;
     display: flex;
     justify-content: space-between;
     margin-bottom: 200px;

     .info {
         width: 532px;

         .title {
            font-size: 70px;
            line-height: 86px;
            color: #080B0B;   
            margin-bottom: 22px; 
            opacity: 0;     
            animation: slideUp 0.8s forwards;   
         }

         .text {
            font-size: 24px;
            line-height: 44px;
            color: rgba(8, 11, 11, 0.7);
            opacity: 0;
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;

            p {
                margin: 0;
            }
         }
    }

    .image {
        width: 483px;
        height: 483px;
        position: relative;
        opacity: 0;
        animation: fadeInScale 0.8s forwards;

        .background {
            position: absolute;
            top: -90px;
            left: -120px;
            animation-delay: 0.4s;
        }

        .book {
            position: absolute;
            top: 40px;
            width: 480px;
            left: -80px;
            opacity: 0;
            animation: slideDown 0.8s forwards;
            animation-delay: 0.8s;
        }
    }

    @media only screen and (max-width: 1300px) {
        .info {
            width: 500px;

            .title {
                font-size: 60px;
                line-height: 76px;
            }

            .text {
                font-size: 22px;
                line-height: 42px;
            }
        }

        .image {
            .background {
                left: -60px;
            }

            .book {
                left: -20px;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 100px;

        .info {
            margin-bottom: 50px;
        }
    }

    @media only screen and (max-width: 650px) {
        margin-bottom: 40px;

        .info {
            width: 100%;
            margin-bottom: 0;

            .title {
                font-size: 50px;
                line-height: 61px;
            }

            .text {
                font-size: 20px;
                line-height: 40px;
            }
        }

        .image {
            transform: scale(0.7);
        }
    }

    @media only screen and (max-width: 450px) {
        margin-bottom: 40px;

        .info {
            .title {
                font-size: 33px;
                line-height: 40px;
            }

            .text {
                font-size: 15px;
                line-height: 30px;
            }
        }

        .image {
            transform: scale(1);
            width: 280px;
            height: 280px;

            .background {
                transform: scale(0.5);
                left: -180px;
                top: -180px;
            }

            .book {
                width: 230px;
                top: 40px;
                left: 10px;
            }
        }
    }
}

.method {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 100px;

    @media only screen and (max-width: 1100px) {
        margin-bottom: 60px;
    }

    .info {
        width: 586px;

        @media only screen and (max-width: 1300px) {
            width: 460px;
        }

        @media only screen and (max-width: 1100px) {
            width: 100%;
        }
    }
    
    .methodNum {
        font-size: 24px;
        line-height: 44px;
        margin-bottom: 4px;
        opacity: 0;

        @media only screen and (max-width: 650px) {
            font-size: 13px;
            line-height: 27px;
        }
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #080B0B;
        margin-bottom: 22px;
        opacity: 0;

        @media only screen and (max-width: 1300px) {
            font-size: 36px;
            line-height: 43px;
        }

        @media only screen and (max-width: 650px) {
            font-size: 21px;
            line-height: 26px;
        }
    }
    
    .text {
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        opacity: 0;
        
        @media only screen and (max-width: 1300px) {
            font-size: 22px;
            line-height: 42px;
        }

        @media only screen and (max-width: 650px) {
            font-size: 13px;
            line-height: 27px;
        }
    }

    &.visible {
        .methodNum {
            animation: slideUp 0.8s forwards;
        }

        .title {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;
        }

        .text {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.8s;
        }
    }


    &.first {
        padding-left: 650px;
        margin-top: 50px;
        min-height: 720px;

        .methodNum {
            color: #FDCF41;
        }

        .image {
            width: 640px;
            height: 640px;
            position: absolute;
            top: 60px;
            left: -40px;

            .background {
                position: absolute;
                top: -60px;
                left: -60px;
                z-index: 1;
                opacity: 0;
            }

            .topPart {
                width: 100%;
                position: relative;
                box-sizing: border-box;
                padding-left: 180px;
                z-index: 2;
                margin-top: 40px;
                margin-bottom: 64px;
                opacity: 0;

                img {
                    position: absolute;
                    top: 0;
                    left: 45px;
                }

                .topText {
                    font-size: 20px;
                    line-height: 40px;
                    color: rgba(8, 11, 11, 0.7);
                    margin-bottom: 10px;
                    padding-top: 20px;
                    width: 342px;

                    span {
                        color: #F55428;
                    }
                }

                .bottomText {
                    font-size: 20px;
                    line-height: 28px;
                    color: #080B0B;

                    span {
                        color: #F55428;
                    }
                }
            }

            .bottomPart {
                width: 100%;
                position: relative;
                z-index: 2;
                padding-left: 50px;
                opacity: 0;

                .topText {
                    width: 283px;
                    font-size: 20px;
                    line-height: 40px;
                    color: rgba(8, 11, 11, 0.7);
                    margin-bottom: 10px;

                    span {
                        color: #F55428;
                    }
                }

                .bottomText {
                    font-size: 20px;
                    line-height: 28px;
                    color: #080B0B;
                    width: 283px;

                    span {
                        color: #F55428;
                    }
                }

                img {
                    position: absolute;
                    left: 364px;
                    top: 0;
                }
            }
        }

        &.visible {
            .image {
                .background {
                    animation: fadeInScale 0.8s forwards;
                }
                .topPart {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.4s;
                }
                .bottomPart {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-left: 540px;
            min-height: 530px;

            .image {
                top: -40px;
                left: -60px;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-left: 0;
            width: 100%;
            padding-bottom: 540px;

            .image {
                top: initial;
                bottom: -70px;
                left: 0;
                right: 0;
                margin: auto;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 320px;

            .image {
                transform: scale(0.4);
                right: initial;
                left: -177px;
                bottom: -175px;
            }
        }
    }

    &.second {
        min-height: 514px;

        .methodNum {
            color: #91D1C5;
        }

        .image {
            width: 564px;
            height: 544px;
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 40px;
            box-sizing: border-box;

            .background {
                position: absolute;
                top: -50px;
                right: -10px;
                z-index: 1;
                opacity: 0;
            }

            .topRow {
                height: 114px;
                display: flex;
                justify-content: flex-start;
                margin-bottom: 38px;
                align-items: baseline;
                position: relative;
                z-index: 2;
                padding-left: 60px;
                opacity: 0;

                .item {
                    &:nth-child(1) {
                        margin-right: 52px;
                    }

                    &:nth-child(2) {
                        margin-right: 25px;
                    }
                }

                .icon {
                    margin-bottom: 22px;
                }

                .text {
                    font-size: 24px;
                    line-height: 44px;
                    color: rgba(8, 11, 11, 0.7);
                    animation-delay: 1.5s;
                }
            }

            .midRow {
                height: 110px;
                display: flex;
                justify-content: flex-start;
                margin-bottom: 76px;
                align-items: baseline;
                position: relative;
                z-index: 2;
                padding-left: 60px;
                opacity: 0;

                .item {
                    &:nth-child(1) {
                        margin-right: 105px;
                    }
                }

                .icon {
                    margin-bottom: 22px;
                }

                .text {
                    font-size: 24px;
                    line-height: 44px;
                    color: rgba(8, 11, 11, 0.7);
                    animation-delay: 1.5s;
                }
            }

            .bottomRow {
                height: 84px;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                position: relative;
                z-index: 2;
                padding-left: 60px;
                opacity: 0;

                .item {
                    &:nth-child(1) {
                        margin-right: 72px;
                    }
                }

                .icon {
                    margin-bottom: 22px;
                }

                .text {
                    font-size: 24px;
                    line-height: 44px;
                    color: rgba(8, 11, 11, 0.7);
                    animation-delay: 1.5s;
                }
            }
        }

        &.visible {
            .image {
                .background {
                    animation: fadeInScale 0.8s forwards;
                }

                .topRow {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.4s;
                }

                .midRow {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }

                .bottomRow {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 1.2s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-right: 540px;
            min-height: 440px;

            .image {
                top: -40px;
                right: -40px;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-right: 0;
            width: 100%;
            padding-bottom: 460px;

            .image {
                top: initial;
                bottom: -70px;
                left: 0;
                right: 0;
                margin: auto;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 300px;

            .image {
                transform: scale(0.5);
                right: initial;
                left: -140px;
                bottom: -155px;
            }
        }
    }

    &.third {
        padding-left: 650px;
        min-height: 720px;

        .methodNum {
            color: #F55428;
        }

        .image {
            width: 635px;
            height: 720px;
            position: absolute;
            top: 0;
            left: -80px;

            .background {
                position: absolute;
                top: 180px;
                z-index: 1;
            }

            .pug {
                position: absolute;
                width: 343px;
                top: 215px;
                left: 100px;
                z-index: 2;
                opacity: 0;
            }

            .pugCloud {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
                opacity: 0;
            }

            .pugTearTop {
                position: absolute;
                top: 300px;
                right: 300px;            
                z-index: 3;
                opacity: 0;
            }

            .pugTearBottom {
                position: absolute;
                top: 364px;
                right: 298px;
                z-index: 3;
                opacity: 0;
            }
            
            .pugCloudText {
                width: 219px;
                position: absolute;
                z-index: 5;
                top: 55px;
                right: 60px;
                font-size: 28px;
                line-height: 44px;
                text-align: center;
                color: #080B0B;
                opacity: 0;

                &.rus {
                    top: 65px;
                }
            }
        }

        &.visible {
            .image {
                .background {
                    animation: fadeInScale 0.8s forwards;
                }

                .pug {
                    animation: slideDown 0.8s forwards;
                    animation-delay: 0.4s;
                }

                .pugCloud {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }

                .pugCloudText {
                    animation: fadeInScale 0.8s forwards;
                    animation-delay: 1.2s;
                }

                .pugTearTop {
                    animation: slideDown 0.8s forwards;
                    animation-delay: 1.2s;
                }

                .pugTearBottom {
                    animation: slideDown 0.8s forwards;
                    animation-delay: 1.5s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-left: 540px;
            min-height: 530px;

            .image {
                top: 80px;
                left: -60px;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-left: 0;
            width: 100%;
            padding-bottom: 550px;

            .image {
                top: initial;
                bottom: -110px;
                left: 0;
                right: 0;
                margin: auto;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 320px;

            .image {
                transform: scale(0.45);
                right: initial;
                left: -180px;
                bottom: -215px;
            }
        }
    }

    &.fourth {
        min-height: 520px;

        .methodNum {
            color: #FDCF41;
        }

        .image {
            width: 581px;
            height: 520px;
            position: absolute;
            top: 20px;
            right: 0;

            .topLine {
                height: 230px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 40px;
                line-height: 49px;
                color: #080B0B;
                margin-bottom: 60px;
                opacity: 0;
                
                .mrBean {
                    width: 292px;
                }
            }

            .bottomLine {
                height: 230px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 40px;
                line-height: 49px;
                color: #080B0B;
                box-sizing: border-box;
                padding-left: 55px;
                opacity: 0;
                
                .chandler {
                    width: 292px;
                }
            }
        }

        &.visible {
            .image {
                .topLine {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.4s;
                }

                .bottomLine {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-right: 540px;
            min-height: 440px;

            .image {
                top: -50px;
                right: -40px;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-right: 0;
            width: 100%;
            padding-bottom: 460px;

            .image {
                top: initial;
                bottom: -70px;
                left: 0;
                right: 0;
                margin: auto;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 320px;

            .image {
                transform: scale(0.5);
                right: initial;
                left: -140px;
                bottom: -125px;
            }
        }
    }

    &.fifth {
        min-height: 620px;
        padding-left: 650px;

        .methodNum {
            color: #91D1C5;
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 612px;
            height: 620px;

            .city {
                position: absolute;
                top: 50px;
                left: 14px;
                width: 584px;
                z-index: 2;
                opacity: 0;
            }

            .largeBanana {
                position: absolute;
                top: 0;
                left: 98px;
                z-index: 1;
                opacity: 0;
            }
            .smallBanana {
                position: absolute;
                top: 120px;
                left: 367px;
                z-index: 1;
                opacity: 0;
            }

            .cityDrips {
                position: absolute;
                top: 241px;
                left: -3px;
                z-index: 1;
                opacity: 0;
                height: 0px;
            }
        }

        &.visible {
            .image {
                .city {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.4s;
                }

                .largeBanana {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }

                .smallBanana {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 0.8s;
                }

                .cityDrips {
                    animation: dripsDown 0.8s forwards;
                    animation-delay: 1.2s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-left: 540px;
            min-height: 530px;
            margin-bottom: 120px;

            .image {
                top: 100px;
                left: -60px;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-left: 0;
            width: 100%;
            padding-bottom: 470px;

            .image {
                top: initial;
                bottom: -130px;
                left: 0;
                right: 0;
                margin: auto;
                transform: scale(0.8);
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 300px;
            margin-bottom: 40px;

            .image {
                transform: scale(0.45);
                right: initial;
                left: -165px;
                bottom: -195px;
            }
        }
    }

    &.sixth {
        min-height: 500px;

        .methodNum {
            color: #F55428;
        }

        .video {
            position: absolute;
            top: 0;
            right: 0;
            width: 586px;
            height: 329px;

            .videoMonster {
                position: absolute;
                top: -52px;
                right: 36px;
                z-index: -1;
                opacity: 0;
            }

            .videoDrips {
                position: absolute;
                z-index: -1;
                top: 128px;
                right: -33px;
                opacity: 0;
            }
        }

        &.visible {
            .video {
                .videoMonster {
                    animation: slideUp 0.8s forwards;
                    animation-delay: 1.2s;
                }

                .videoDrips {
                    animation: videoDripsDown 0.8s forwards;
                    animation-delay: 1.5s;
                }
            }
        }

        @media only screen and (max-width: 1300px) {
            padding-right: 540px;
            min-height: 440px;
    
            .video {
                width: 512px;
                height: 286px;

                .videoMonster {
                    top: -32px;
                    right: 26px;
                    width: 120px;
                }

                .videoDrips {
                    right: -28px;
                    width: 569px;
                }
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-right: 0;
            width: 100%;
            padding-bottom: 500px;

            .video {
                top: initial;
                bottom: 150px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        @media only screen and (max-width: 650px) {
            padding-bottom: 300px;

            .video {
                width: 288px;
                height: 160px;
                bottom: 100px;
                left: 0;
                right: initial;

                .videoMonster {
                    top: -15px;
                    right: 18px;
                    width: 60px;
                }

                .videoDrips {
                    top: 75px;
                    right: -16px;
                    width: 320px;
                }
            }
        }
    }
}

.lawFirm {
    margin-bottom: 100px;
    text-align: center;
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    opacity: 0;

    a {
        color: #91D1C5;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 650px) {
        font-size: 20px;
        line-height: 30px;
    }

    &.visible {
        animation: slideUp 0.8s forwards;
        animation-delay: 0.4s;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes slideDown{
    0%{
        opacity: 0;
        transform: translateY(-20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes dripsDown{
    0%{
        opacity: 0;
        height: 0px;
        width: 100%;
    }
    35%{
        opacity: 0;
        width: 100%;
    }
    100%{
        opacity: 1;
        height: 387px;
        width: 100%;
    }
}

@keyframes videoDripsDown{
    0%{
        opacity: 0;
        transform: translateY(-100px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}