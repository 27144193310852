.shop {
    padding-top: 103px;

    @media only screen and (max-width: 600px) {
        padding-top: 48px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.title {
    font-size: 70px;
    line-height: 86px;
    color: #080B0B;
}

.products {
    margin-top: 93px;

    @media only screen and (max-width: 600px) {
        margin-top: 40px;
    }
}