.title {
    font-size: 70px;
    line-height: 86px;
    position: relative;
    z-index: 10;
    color: #080B0B;
    margin: 0;
    opacity: 0;
    
    &.visible {
        animation: slideUp 1s forwards;
    }

    @media only screen and (max-width: 1300px) {
        font-size: 60px;
        line-height: 76px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 50px;
        line-height: 61px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 33px;
        line-height: 40px;
    }
}

.center {
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: 1100px) {
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;

        &.callToAction {
            text-align: center;
        }
    }

}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}