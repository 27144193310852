.container {
    height: 100px;
    line-height: 100px;
    float: right;
    position: relative;
    width: 186px;
    outline: none;

    &.inHeader {
        @media only screen and (max-width: 1100px) {
            display: none;
        }
    }

    &.inFooter,
    &.inMenu {
        .dropdown {
            top: initial;
            bottom: 59px;
        }

        .dropdownBackground {
            transform: rotate(180deg);
        }

        .dropdownInner {
            bottom: 0;
            top: 10px;
        }
    }
}

.control {
    height: 100px;
    width: 100%;
    line-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: #080B0B;

    &.white {
        color: #fff;
    }
}

.pin {
    margin-right: 11px;
}

.arrow {
    margin-left: 7px;
    transition: 0.3s;
}

.dropdown {
    position: absolute;
    top: 59px;
    left: -18px;
    width: 214px;
    height: 200px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: 0.3s;
    z-index: 20;
}

.dropdownInner {
    width: 92px;
    height: 92px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.option{
    color: #000;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    cursor: pointer;
}

.container:hover .dropdown{
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.container:hover .arrow{
    transform: rotate(180deg);
}