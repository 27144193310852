.container {
    height: 100px;
    line-height: 100px;
    float: right;
    position: relative;
    width: 166px;
}

.control {
    height: 100px;
    width: 100%;
    line-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: #080B0B;
    
    &.white {
        color: #fff;    
    }
}

.pin {
    margin-right: 11px;
}

.arrow {
    margin-left: 7px;
    transition: 0.3s;
}

.dropdown {
    position: absolute;
    left: -18px;
    bottom: 59px;
    width: 214px;
    height: 200px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: 0.3s;
    z-index: 30;
}

.dropdownInner {
    width: 142px;
    height: 92px;
    position: absolute;
    margin: auto;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.option{
    color: #000;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.container:hover .dropdown{
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.container:hover .arrow{
    transform: rotate(180deg);
}

.dropdownBackground {
    transform: rotate(180deg);
}