.menuButton {
    width: 32px;
    height: 32px;
    position: relative;
    margin-top: 32px;
    margin-right: 8px;
    float: right;
    cursor: pointer;
    outline: none;

    img {
        outline: none;
    }

    .menu {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .close {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
    }

    &.isOpened {
        .menu {
            opacity: 0;
            pointer-events: none;
        }

        .close {
            opacity: 1;
            pointer-events: all;
        }
    }
}