.wrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.corporate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 106px;
    width: 100%;

    .info {
        width: 691px;
        padding-top: 15px;
    }

    .image {
        width: 440px;
        transform-origin: 50% 0;
        opacity: 0;

        img {
            width: 100%;
        }
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #080B0B;
        margin-bottom: 20px;
        opacity: 0;
        text-decoration: none;
    }
    
    .text {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        opacity: 0;
    }

    .price,
    .place,
    .time {
        color: #91D1C5;
    }

    @media only screen and (max-width: 1300px) {
        .info {
            width: 600px;
        }

        .image {
            width: 380px;
        }
    }

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;

        .info {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 450px) {
        .title {
            font-size: 21px;
            line-height: 26px;
        }

        .text {
            font-size: 13px;
            line-height: 27px;
        }

        .image {
            width: 300px;
        }
    }
}

.individual {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    width: 100%;

    .info {
        width: 691px

    }

    .image {
        width: 440px;
        transform-origin: 50% 0;
        opacity: 0;

        img {
            width: 100%;
        }
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #080B0B;
        margin-bottom: 20px;
        opacity: 0;
        text-decoration: none;
    }
    
    .text {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        opacity: 0;
    }

    .price,
    .place,
    .time {
        color: #FDCF41;
    }

    @media only screen and (max-width: 1300px) {
        .info {
            width: 600px;
        }

        .image {
            width: 380px;
        }
    }

    @media only screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        align-items: center;

        .info {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 450px) {
        .title {
            font-size: 21px;
            line-height: 26px;
        }

        .text {
            font-size: 13px;
            line-height: 27px;
        }

        .image {
            width: 300px;
        }
    }
}

.details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    opacity: 0;
}

.price {
    padding-left: 35px;
    box-sizing: border-box;
    position: relative;
    line-height: 52px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }

}

.time {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 50px;
    margin-bottom: 21px;
    margin-right: 14px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.place {
    padding-left: 50px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.companies {
    width: 100%;
    position: relative;
    padding: 112px 0 120px;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    
    .wrapper {
        z-index: 6;
        position: relative;
    }

    .title {
        color: #fff;
        font-size: 40px;
        line-height: 49px;
        margin-bottom: 40px;
        text-align: center;
        opacity: 0;
    }

    .companiesList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        opacity: 0;

        .companyItem {
            width: 176px;

            img {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        background-color: #F55428;

        .background {
            display: none;
        }

        .companiesList {
            flex-wrap: wrap;
        }

        .companyItem {
            width: 132px;
            margin-bottom: 14px;
        }
    }

    @media only screen and (max-width: 450px) {
        flex-direction: column;
        justify-content: center;
    }
}

.visible {
    &.individual {
        .title {
            animation: slideUp 0.6s forwards;
        }

        .text {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.4s;
        }

        .image {
            animation: swinging 6s ease-in-out forwards;
        }

        .details {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.6s;
        }
    }

    &.corporate {
        .title {
            animation: slideUp 0.6s forwards;
        }

        .text {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.4s;
        }

        .image {
            animation: swinging 6s ease-in-out forwards;
        }

        .details {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.6s;
        }
    }

    &.wrapper {
        .title {
            animation: slideUp 0.8s forwards;
        }

        .companiesList {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;
        }
    }
}

@keyframes swinging{
    0%{
        transform: rotate(10deg);
        opacity: 0;
    }
    20%{
        transform: rotate(-10deg);
        opacity: 1;
    }
    40%{
        transform: rotate(5deg)
    }
    60%{
        transform: rotate(-5deg)
    }
    80%{
        transform: rotate(2deg)
    }
    100%{
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes rotateInLeft{
    0%{
        opacity: 0;
        transform: rotate(-15deg)
    }
    100%{
        opacity: 1;
        transform: rotate(0deg)
    }
}
