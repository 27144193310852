.hero {
    width: 1200px;
    margin: 0 auto;
    padding-top: 250px;
    margin-bottom: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
        padding-top: 100px;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        flex-direction: column;
        padding-top: 60px;
    }

    @media only screen and (max-width: 650px) {
        padding-top: 30px;
        margin-bottom: 40px;
        overflow: hidden;
        margin-bottom: -100px;
    }

    @media only screen and (max-width: 350px) {
        margin-bottom: -120px;
    }
}

.info {
    width: 593px;
    margin-bottom: 100px;

    .title {
        font-size: 70px;
        line-height: 86px;
        color: #080B0B;
        margin-bottom: 21px;
        margin-top: 0;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.4s;
    }

    @media only screen and (max-width: 1300px) {
        width: 400px;

        .title {
            font-size: 60px;
            line-height: 76px;
        }
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 650px) {
        .title {
            font-size: 50px;
            line-height: 61px;
            text-align: center;
        }
    }

    @media only screen and (max-width: 450px) {
        .title {
            font-size: 33px;
            line-height: 40px;
        }
    }
}

.description {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 28px;
    margin-top: 0;
    opacity: 0;
    animation: slideUp 0.8s forwards;
    animation-delay: 0.6s;

    p {
        margin-top: 0;
    }

    @media only screen and (max-width: 1300px) {
        font-size: 22px;
        line-height: 42px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 20px;
        line-height: 40px;
        text-align: center;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.image {
    width: 600px;
    height: 600px;
    position: relative;
    margin-right: -50px;
    
    .background {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: fadeInScale 0.8s forwards;
    }

    .cvitanna {
        width: 652px;
        position: absolute;
        top: -139px;
        right: -49px;
        opacity: 0;
        animation: slideDown 0.8s forwards;
        animation-delay: 0.4s;
    }

    .cap {
        position: absolute;
        top: -150px;
        right: 188px;
        opacity: 0;
        animation: slideDown 0.8s forwards;
        animation-delay: 0.8s;
    }

    .wand {
        position: absolute;
        top: 196px;
        right: 229px;
        opacity: 0;
        animation: slideDown 0.8s forwards;
        animation-delay: 1s;
    }

    .cloud {
        position: absolute;
        top: -110px;
        left: -100px;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 1.2s;
    }

    .cloudText {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        color: #080B0B;
        width: 219px;
        height: 38px;
        position: absolute;
        top: -25px;
        left: -40px;
        opacity: 0;
        animation: fadeInScale 0.8s forwards;
        animation-delay: 1.5s;
    }

    @media only screen and (max-width: 1300px) {
        transform: scale(0.8);
    }

    @media only screen and (max-width: 650px) {
        transform: scale(0.5);
        margin-right: -30px;
        margin-top: -60px;
    }

    @media only screen and (max-width: 350px) {
        transform: scale(0.4);   
        margin-top: -110px;
    }
}

.faqWrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes slideDown{
    0%{
        opacity: 0;
        transform: translateY(-20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}