.section {
    margin-bottom: 97px;
    opacity: 0;

    &.visible {
        animation: slideUp 0.8s forwards;
    }

    &:nth-child(2n + 2) {
        .sectionNum {
            color: #91D1C5;
        }
    }

    &:nth-child(3n + 3) {
        .sectionNum {
            color: #FDCF41;
        }
    }

    @media only screen and (max-width: 650px) {
        margin-bottom: 54px;
    }
}

.sectionNum {
    font-size: 24px;
    line-height: 44px;
    color: #F55428;

    @media only screen and (max-width: 650px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.title {
    font-size: 70px;
    line-height: 86px;
    margin-bottom: 32px;
    color: #080B0B; 

    @media only screen and (max-width: 1300px) {
        font-size: 60px;
        line-height: 76px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 33px;
        line-height: 50px;
        margin-bottom: 24px;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}