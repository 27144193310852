.event {
    padding: 122px 0;

    @media only screen and (max-width: 1100px) {
        padding: 36px 0 66px;
    }
}

.clipSvg {
    position: absolute;
    opacity: 0;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    padding-left: 510px;
    position: relative;
    box-sizing: border-box;
    min-height: 500px;
    opacity: 0;
    animation: slideUp 1s forwards;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        padding-top: 456px;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 280px;
    }
}

.photo {
    width: 448px;
    height: 418px;
    clip-path: url(#eventPhotoPath);
    transform: translate3d(0, 0, 0);
    position: absolute;
    top: 0;
    left: 16px;
    overflow: hidden;

    img {
        height: 100%;
        width: auto;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
    }

    @media only screen and (max-width: 600px) {
        clip-path: url(#eventPhotoPathMobile);
        width: 280px;
        height: 280px;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
        font-size: 33px;
        line-height: 50px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 40px;

    @media only screen and (max-width: 600px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.optionsContainer {
    min-height: 54px;
    width: 100%;
    font-size: 0;
}

.option {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 20px;

    .icon {
        vertical-align: middle;
        margin-right: 10px;
        height: 54px;
        display: inline-block;

        @media only screen and (max-width: 600px) {
            height: 36px;
            position: absolute;
            top: 0;
            left: 0;

            &.price {
                left: 10px;
            }

            &.pin {
                left: 6px;
            }
        }
    }

    span {
        font-size: 18px;
        line-height: 54px;
        color: #91D1C5;
        vertical-align: middle;
        display: inline-block;

        @media only screen and (max-width: 600px) {
            font-size: 16px;
            line-height: 29px;
        }
    }

    @media only screen and (max-width: 600px) {
        display: block;
        position: relative;
        padding-left: 46px;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}
