.hiring {
    padding-top: 102px;

    @media only screen and (max-width: 1100px) {
        padding-top: 20px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.vacancies {
    margin-top: 40px;
    margin-bottom: 128px;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 1100px) {
        justify-content: space-around;
        margin-bottom: 60px;
        margin-top: 30px;
    }
}