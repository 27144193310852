.service {
    padding: 122px 0;

    @media only screen and (max-width: 1100px) {
        padding: 36px 0 66px;
    }
}

.clipSvg {
    position: absolute;
    opacity: 0;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    padding-left: 510px;
    position: relative;
    box-sizing: border-box;
    min-height: 500px;
    opacity: 0;
    animation: slideUp 1s forwards;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        padding-top: 456px;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 0;
    }
}

.photo {
    width: 448px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    
    img {
        height: auto;
        width: 100%;
    }
    
    @media only screen and (max-width: 600px) {
        width: 288px;
        height: auto;
        margin-bottom: 30px;
        position: relative;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
        font-size: 33px;
        line-height: 50px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 40px;

    @media only screen and (max-width: 600px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.optionsContainer {
    min-height: 54px;
    width: 100%;
    font-size: 0;

    .option {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        margin-bottom: 20px;
    
        .icon {
            vertical-align: middle;
            margin-right: 10px;
            height: 54px;
            display: inline-block;
    
            @media only screen and (max-width: 600px) {
                height: 36px;
                position: absolute;
                top: 0;
                left: 0;
    
                &.priceIcon {
                    left: 10px;
                }
    
                &.pinIcon {
                    left: 6px;
                }
            }
        }
    
        span {
            font-size: 18px;
            line-height: 54px;
            color: #91D1C5;
            vertical-align: middle;
            display: inline-block;
    
            @media only screen and (max-width: 600px) {
                font-size: 16px;
                line-height: 29px;
            }
        }
    
        @media only screen and (max-width: 600px) {
            display: block;
            position: relative;
            padding-left: 46px;
        }
    }
}

// individual

.badge {
    width: 135px;
    height: 43px;
    position: relative;
    margin-bottom: 30px;

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .badgeText {
        color: #fff;
        font-size: 24px;
        line-height: 43px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 450px) {
        transform: scale(0.8);
    }
}

.details {
    display: flex;
    justify-content: flex-start;
}

.online {
    margin-right: 75px;

    @media only screen and (max-width: 450px) {
        margin-right: 25px;
    }
    
    .price,
    .place,
    .time,
    .person {
        color: #F0582E;
    }
}

.offline {
    .price,
    .place,
    .time,
    .person {
        color: #FDCF41;
    }
}

.price {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 52px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 15px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.time {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 50px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.place {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 6px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.person {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 2px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

// corporate

.corporateDetails {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: #91D1C5;

    .price {
        padding-left: 35px;
        box-sizing: border-box;
        position: relative;
        line-height: 52px;
        margin-bottom: 21px;
        margin-right: 14px;
    
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    
        @media only screen and (max-width: 450px) {
            font-size: 15px;
            line-height: 32px;
            padding-left: 45px;
    
            img {
                height: 32px;
            }
        }
    
    }
    
    .time {
        padding-left: 60px;
        box-sizing: border-box;
        position: relative;
        line-height: 50px;
        margin-bottom: 21px;
        margin-right: 14px;
    
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    
        @media only screen and (max-width: 450px) {
            font-size: 15px;
            line-height: 32px;
            padding-left: 45px;
    
            img {
                height: 32px;
            }
        }
    }
    
    .place {
        padding-left: 50px;
        box-sizing: border-box;
        position: relative;
        line-height: 55px;
        margin-bottom: 21px;
    
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    
        @media only screen and (max-width: 450px) {
            font-size: 15px;
            line-height: 32px;
            padding-left: 45px;
    
            img {
                height: 32px;
            }
        }
    }
}


// animation

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}