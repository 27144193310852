@font-face {
  font-family: 'monsters';
  src: local('FiraSans'), url(./assets/fonts/monsters.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  font-family: 'monsters';
}

*::-webkit-scrollbar{
	display: none;	
}

#root {
  height: 100%;
}

html {
  height: 100%;
  outline: none;
}

.page-main{
  height: 100%;
}

/* .fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: .3s;
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  transition: .3s;
  opacity: 0;
} */
