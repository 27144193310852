.reviews {
    min-height: 468px;
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 40px;

    @media only screen and (max-width: 1100px) {
        margin-top: 100px;
    }
}

.slideOuter {
    min-height: 468px;
}

.slide {
    min-height: 468px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 183px;
    box-sizing: border-box;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 440px;
    }

    @media only screen and (max-width: 700px) {
        padding-bottom: 320px;
    }

    @media only screen and (max-width: 400px) {
        padding-bottom: 230px;
    }
}

.nextArrow {
    color: #fff;
    font-size: 40px;
    opacity: 0.3;
    transition: 0.3s;
    position: absolute;
    top: 250px;
    // bottom: 0;
    // margin: auto;
    width: 36px;
    height: 40px;
    right: 12vw;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        opacity: 1;
    }

    @media only screen and (max-width: 1300px) {
        right: 40px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.prevArrow {
    color: #fff;
    font-size: 40px;
    opacity: 0.3;
    transition: 0.3s;
    position: absolute;
    position: absolute;
    top: 250px;
    // bottom: 0;
    // margin: auto;
    width: 36px;
    height: 40px;
    left: 12vw;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        opacity: 1;
    }

    @media only screen and (max-width: 1300px) {
        left: 40px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.cloud {
    margin-left: 100px;
    position: relative;
    z-index: 9;
    width: 670px;
    height: 100%;

    
    .cloudTop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    
    .cloudBottom {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    
    .cloudText {
        font-size: 24px;
        line-height: 44px;
        color: #000;
        padding: 50px 36px;
        
        p {
            margin: 0;
        }
    }
    
    .cloudCenter {
        background-color: #fff;
        width: 670px;
        left: 0;
        top: 81px;
        height: calc(100% - 154px);
        position: absolute;
        z-index: -1;
    }

    @media only screen and (max-width: 1300px) {
        margin-left: 20px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        margin-left: 0;

        .cloudTop {
            width: 100%;
        }
        
        .cloudBottom {
            width: 100%;
        }
        
        .cloudCenter {
            width: calc(100% - 4px);
            left: 2px;
        }
    }

    @media only screen and (max-width: 700px) {
        width: 393px;

        .cloudTop {
            width: 100%;
        }
        
        .cloudBottom {
            width: 100.3%;
        }
        
        .cloudCenter {
            width: 100%;
            left: 0;
            top: 41px;
            height: calc(100% - 84px);
        }

        .cloudText {
            font-size: 15px;
            line-height: 30px;
        }
    }

    @media only screen and (max-width: 400px) {
        width: 300px;

        .cloudTop {
            width: 100%;
        }
        
        .cloudBottom {
            width: 100%;
        }
        
        .cloudCenter {
            width: 299px;
            top: 36px;
            height: calc(100% - 66px);
        }
    }
}

.photo {
    position: absolute;
    bottom: 0;
    right: 50px;
    z-index: 11;
    max-width: 450px;
    max-height: 450px;
    
    @media only screen and (max-width: 1300px) {
        right: 0;
        max-width: 100%;
    }

    @media only screen and (max-width: 400px) {
        max-width: 80%;
    }

    @media only screen and (max-width: 380px) {
        max-width: 90%;
        max-height: 270px;
    }
    
}


:global{
    .reviews-slick-dots {
        width: 140px;
        height: 11px;
        position: absolute;
        bottom: 30px;
        margin: auto;
        left: 0;
        right: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        font-size: 0;

        li {
            width: 11px;
            height: 11px;
            display: inline-block;
            margin-right: 8px;
            background-image: url(../../../assets/img/slider-dot.svg);
            opacity: 0.3;
            transition: 0.3s;
            
            &:hover {
                opacity: 0.6;
            }

            button {
                opacity: 0;
                cursor: pointer;
                width: 11px;
                height: 11px;
            }

            &.slick-active {
                opacity: 1;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @media only screen and (max-width: 1100px) {
            bottom: initial;
            top: -50px;
        }
    }
}