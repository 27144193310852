.wrapper {
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.individual {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    width: 100%;

    .info {
        width: 691px

    }

    .image {
        width: 440px;
        transform-origin: 50% 0;
        opacity: 0;

        img {
            width: 100%;
        }
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #080B0B;
        margin-bottom: 20px;
        opacity: 0;
        text-decoration: none;
    }
    
    .text {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {
        .info {
            width: 600px;
        }

        .image {
            width: 380px;
        }
    }

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;

        .info {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 450px) {
        .title {
            font-size: 21px;
            line-height: 26px;
        }

        .text {
            font-size: 13px;
            line-height: 27px;
        }

        .image {
            width: 300px;
        }
    }
}

.corporate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    width: 100%;

    .info {
        width: 668px
    }

    .image {
        width: 478px;
        transform-origin: 50% 0;
        opacity: 0;

        img {
            width: 100%;
        }
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #080B0B;
        margin-bottom: 20px;
        opacity: 0;
        text-decoration: none;
    }
    
    .text {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {
        .info {
            width: 600px;
        }

        .image {
            width: 380px;
        }
    }

    @media only screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        align-items: center;

        .info {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 450px) {
        .title {
            font-size: 21px;
            line-height: 26px;
        }

        .text {
            font-size: 13px;
            line-height: 27px;
        }

        .image {
            width: 300px;
        }
    }
}

.badge {
    width: 135px;
    height: 43px;
    position: relative;
    margin-bottom: 30px;

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .badgeText {
        color: #fff;
        font-size: 24px;
        line-height: 43px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 450px) {
        transform: scale(0.8);
    }
}

.details {
    display: flex;
    justify-content: flex-start;
    opacity: 0;
}

.online {
    margin-right: 75px;

    @media only screen and (max-width: 450px) {
        margin-right: 25px;
    }
    
    .price,
    .place,
    .time,
    .person {
        color: #F0582E;
    }
}

.offline {
    .price,
    .place,
    .time,
    .person {
        color: #FDCF41;
    }
}

.price {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 52px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 15px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.time {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 50px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.place {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 6px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.person {
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    line-height: 55px;
    margin-bottom: 21px;
    font-size: 24px;

    img {
        position: absolute;
        top: 0;
        left: 2px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 32px;
        padding-left: 45px;

        img {
            height: 32px;
        }
    }
}

.includes {
    width: 1200px;
    margin: 0 auto;
    padding-top: 112px;
    position: relative;
    margin-bottom: 108px;

    .circle {
        position: absolute;
        left: 264px;
        top: 0;
        opacity: 0;
    }

    .glasses {
        position: absolute;
        top: 243px;
        left: 792px;
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {
        width: 1000px;

        .circle {
            width: 720px;
            left: 264px;
            top: 30px;
        }

        .glasses {
            top: 243px;
            left: 782px;
            width: 210px;
        }
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-top: 40px;

        .circle {
            display: none;
        }

        .glasses {
            display: none;
        }
    }
}

.includesList {
    margin-top: 68px;

    @media only screen and (max-width: 1300px) {
        width: 100%;
        max-width: 840px;
    }
}

.includesItem {
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 78px;
    font-size: 24px;
    color: #080B0B;
    position: relative;
    margin-bottom: 28px;
    opacity: 0;

    .iconContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 54px;
        height: 54px;

        img {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    @media only screen and (max-width: 1300px) {
        min-height: 54px;
        height: auto;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
    }
}

.onlineOrOffline {
    position: relative;
    padding: 112px 0 120px;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        z-index: 6;
        position: relative;
        align-items: center;
    }

    .info {
        width: 692px;
    }

    .portrait {
        width: 428px;
        transform-origin: 50% 0;
        opacity: 0;
    }

    .title {
        font-size: 40px;
        line-height: 49px;
        color: #fff;
        margin-bottom: 20px;
        opacity: 0;
    }
    
    .text {
        font-size: 24px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.85);
        opacity: 0;
    }


    @media only screen and (max-width: 1300px) {
        .portrait {
            width: 340px;
        }

        .info {
            width: 565px;
        }
    }

    @media only screen and (max-width: 1100px) {
        background-color: #F55428;

        .background {
            display: none;
        }

        .wrapper {
            flex-direction: column;
        }

        .info {
            width: 100%;
            margin-bottom: 40px;
        }

        @media only screen and (max-width: 450px) {
            .title {
                font-size: 21px;
                line-height: 26px;
            }

            .text {
                font-size: 13px;
                line-height: 27px;
            }

            .portrait {
                width: 300px;
            }
        }
    }
}

.visible {
    &.individual {
        .title {
            animation: slideUp 0.6s forwards;
        }

        .text {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.4s;
        }

        .image {
            animation: swinging 6s ease-in-out forwards;
        }

        .details {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.6s;
        }
    }

    &.corporate {
        .title {
            animation: slideUp 0.6s forwards;
        }

        .text {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.4s;
        }

        .image {
            animation: swinging 6s ease-in-out forwards;
        }

        .details {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.6s;
        }
    }

    &.includes {
        .circle {
            animation: fadeInScale 0.8s forwards;
        }

        .glasses {
            animation: rotateInLeft 0.8s forwards;
            animation-delay: 0.4s;
        }

        .includesItem {
            animation: slideUp 0.6s forwards;
            
            &:nth-child(1) {
                animation-delay: 0.4s;
            }

            &:nth-child(2) {
                animation-delay: 0.5s;
            }

            &:nth-child(3) {
                animation-delay: 0.6s;
            }

            &:nth-child(4) {
                animation-delay: 0.7s;
            }

            &:nth-child(5) {
                animation-delay: 0.8s;
            }

            &:nth-child(6) {
                animation-delay: 0.9s;
            }
        }
    }

    &.onlineOrOffline {
        .title {
            animation: slideUp 0.6s forwards;
        }

        .text {
            animation: slideUp 0.6s forwards;
            animation-delay: 0.3s;
        }

        .portrait {
            animation: swinging 6s ease-in-out forwards;
        }
    }
}

@keyframes swinging{
    0%{
        transform: rotate(6deg);
        opacity: 0;
    }
    20%{
        transform: rotate(-6deg);
        opacity: 1;
    }
    40%{
        transform: rotate(4deg)
    }
    60%{
        transform: rotate(-4deg)
    }
    80%{
        transform: rotate(2deg)
    }
    100%{
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes rotateInLeft{
    0%{
        opacity: 0;
        transform: rotate(-15deg)
    }
    100%{
        opacity: 1;
        transform: rotate(0deg)
    }
}