.monstersSlider {
    margin-top: 27px;
    margin-bottom: 115px;

    * {
        outline: none;
    }
}

.navMonsters {
    width: 1200px;
    height: 134px;
    margin: 0 auto 40px;
    
    .navMonster {
        height: 134px;
        width: 134px;
        position: relative;
        cursor: pointer;

        .navMonsterImg {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 134px;
            filter: grayscale(1);
        }

        .navMonsterImgHover {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 134px;
            opacity: 0;
        }

        &:hover,
        &.active {
            .navMonsterImg {
                opacity: 0;
            }

            .navMonsterImgHover {
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        height: 74px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        box-sizing: border-box;

        .navMonster {
            width: 74px;
            height: 74px;

            .navMonsterImg {
                height: 74px;
            }
    
            .navMonsterImgHover {
                height: 74px;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        height: 64px;
        padding: 0;

        .navMonster {
            width: 64px;
            height: 64px;

            .navMonsterImg {
                height: 64px;
            }
    
            .navMonsterImgHover {
                height: 64px;
            }
        }
    }
}

:global {
    .slick-current {
        .nav-monster-img {
            opacity: 0 !important;
        }

        .nav-monster-img-hover {
            opacity: 1 !important;
        } 
    }
}
    
.slider {
    max-width: 100%;
}

.slide {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .photo {
        width: 487px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        flex-direction: column;

        .photo {
            width: 100%;
            max-width: 487px;
        }
    }
}

.info {
    width: 552px;

    .header {
        min-height: 49px;
        margin-bottom: 19px;

        .name {
            font-size: 40px;
            line-height: 49px;
            color: #080B0B;
            margin-right: 15px;
            display: inline-block;
            vertical-align: top;
        }

        .position {
            display: inline-block;
            vertical-align: top;
            padding: 0 23px;
            height: 49px;
            width: auto;
            box-sizing: border-box;
            position: relative;
            line-height: 49px;
            color: #ffffff;
            font-size: 24px;

            .labelBackground {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
    }

    .description {
        font-size: 24px;
        line-height: 44px;
        color: rgba(8, 11, 11, 0.7);
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
    }

    @media only screen and (max-width: 700px) {
        .header {
            .name {
                font-size: 24px;
                line-height: 29px;
                display: block;
                margin-right: 0;
                margin-bottom: 11px;
            }
    
            .position {
                height: 42px;
                line-height: 42px;
                font-size: 16px;
            }
        }
        
        .description {
            font-size: 15px;
            line-height: 30px;
        }
    }
}


.nextArrow {
    color: rgba($color: #080B0B, $alpha: 0.1);
    font-size: 40px;
    transition: 0.3s;
    position: absolute;
    top: 50px;
    width: 36px;
    height: 40px;
    right: -50px;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        color: rgba($color: #080B0B, $alpha: 0.3);
    }

    @media only screen and (max-width: 1300px) {
        right: -20px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.prevArrow {
    color: rgba($color: #080B0B, $alpha: 0.1);
    font-size: 40px;
    transition: 0.3s;
    position: absolute;
    position: absolute;
    top: 50px;
    width: 36px;
    height: 40px;
    left: -50px;
    line-height: 40px;
    cursor: pointer;
    z-index: 10000;

    &:hover {
        color: rgba($color: #080B0B, $alpha: 0.3);
    }

    @media only screen and (max-width: 1300px) {
        left: -20px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}