.sliderButtonOuter {
    width: 100%;
    animation: slideUp 0.8s forwards;
    animation-delay: 0.8s;
    opacity: 0;
}

.sliderButton {
    width: 387px;
    height: 64px;
    position: relative;
    margin: 0 auto 124px;
    z-index: 1;

    @media only screen and (max-width: 1100px) {
        margin-bottom: 65px;
    }

    @media only screen and (max-width: 400px) {
        transform: scale(0.7) translateX(-50px);
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

.sliderBorder {
    position: absolute;
    top: 0;
    left: 0;
}

.leftBtnText {
    width: 164px;
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 64px;
    z-index: 3;
    font-size: 24px;
    color: #080B0B;
    cursor: pointer;

    &.active {
        color: #fff;
    }
}

.rightBtnText {
    width: 233px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 64px;
    z-index: 3;
    font-size: 24px;
    color: #080B0B;
    cursor: pointer;

    &.active {
        color: #fff;
    }
}

.leftBtn {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    transform: translateX(225px);
    transition: 0.3s;

    &.active {
        opacity: 1;
        transform: translateX(0);
    }   
}

.rightBtn {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    transform: translateX(0);
    transition: 0.3s;

    &.active {
        opacity: 1;
        transform: translateX(154px);
    }
}