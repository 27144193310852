.faqItem {
    margin-bottom: 37px;

    &.opened {
        .answer {
            padding-top: 23px;
            max-height: 2000px;
        }

        .arrow {
            transform: rotate(180deg);
        }
    }

    @media only screen and (max-width: 650px) {
        &.opened {
            .answer {
                padding-top: 12px;
                max-height: 2000px;
            }
        }
    }
}

.question {
    position: relative;
    padding-left: 37px;
    width: 100%;
    box-sizing: border-box;
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    cursor: pointer;

    @media only screen and (max-width: 650px) {
        font-size: 21px;
        line-height: 26px;
        padding-left: 21px;
    }
}

.arrow {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s ease-in-out;

    @media only screen and (max-width: 650px) {
        width: 11px;
    }
}

.answer {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    padding-left: 37px;
    box-sizing: border-box;
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    @media only screen and (max-width: 650px) {
        padding-left: 21px;
        font-size: 13px;
        line-height: 27px;
    }
}