.hero {
    padding-top: 95px;
    width: 1200px;
    margin: 0 auto 120px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 30px;
        margin-bottom: 60px;
    }
}

.subtitle {
    width: 1016px;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: rgba(8, 11, 11, 0.7);
    margin: 32px auto 10px;
    opacity: 0;
    animation: slideUp 0.8s forwards;
    animation-delay: 0.4s;

    @media only screen and (max-width: 1300px) {
        width: 100%;
    }

    @media only screen and (max-width: 450px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.image {
    width: 668px;
    height: 210px;
    position: relative;
    margin: 0 auto;

    .goodTeacher {
        width: 188px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.6s;
    }

    .goodTeacherLabel {
        position: absolute;
        bottom: 2px;
        left: 3px;
        z-index: 2;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.8s;
    }

    .goodTeacherLabelText {
        position: absolute;
        bottom: 12px;
        left: 12px;
        z-index: 2;
        width: 155px;
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.8s;
    }

    .betterTeacher {
        width: 192px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.6s;
    }

    .betterTeacherLabel {
        position: absolute;
        right: 0;
        z-index: 2;
        bottom: 0;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.8s;
    }

    .betterTeacherLabelText {
        position: absolute;
        bottom: 12px;
        right: 0;
        z-index: 2;
        width: 195px;
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 0.8s;

        .rus {
            font-size: 15px;
        }
    }

    .betterTeacherCrown {
        position: absolute;
        top: 6px;
        right: 80px;    
        z-index: 2;
        opacity: 0;
        animation: slideDown 0.8s forwards;
        animation-delay: 1s;
    }

    .betterTeacherDouble {
        font-size: 32px;
        line-height: 44px;
        color: #F0582E;
        position: absolute;
        top: 0;
        right: 40px;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 1.2s;
    }

    .teacherAdvantages {
        position: absolute;
        top: 70px;
        left: 200px;

        .item {
            font-size: 18px;
            line-height: 36px;
            color: #F0582E;
            height: 36px;
            padding-left: 30px;
            position: relative;
            opacity: 0;
            animation: slideUp 0.8s forwards;
            animation-delay: 0.8s;

            &:nth-child(2) {
                animation-delay: 0.9s;
            }

            &:nth-child(3) {
                animation-delay: 1s;
            }
        }

        .x {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .betterTeacherEqual {
        font-size: 26px;
        line-height: 44px;
        color: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 450px;
        top: 100px;
        opacity: 0;
        animation: slideUp 0.8s forwards;
        animation-delay: 1.1s;
    }

    @media only screen and (max-width: 780px) {
        width: 288px;
        height: 345px;   

        .goodTeacher {
            width: 128px;
        }
    
        .goodTeacherLabel {
            width: 116px;
            bottom: 205px;
            left: 0;
        }
    
        .goodTeacherLabelText {
            position: absolute;
            bottom: 205px;
            left: 0;
            z-index: 2;
            width: 116px;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
        }
    
        .betterTeacher {
            width: 134px;
            position: absolute;
            top: initial;
            right: 0;
            left: 0;
            margin: auto;
            bottom: 0;
        }
    
        .betterTeacherLabel {
            top: initial;
            right: 0;
            left: 0;
            margin: auto;
            bottom: 5px;
            width: 134px;
        }
    
        .betterTeacherLabelText {
            width: 134px;
            font-size: 12px;
            bottom: 9px;
            right: 76px;
    
            .rus {
                font-size: 11px;
            }
        }
    
        .betterTeacherCrown {
            top: 203px;
            right: 133px;
            width: 30px;
        }
    
        .betterTeacherDouble {
            font-size: 22px;
            line-height: 32px;
            top: 200px;
            right: 100px;
        }
    
        .teacherAdvantages {
            position: absolute;
            top: 30px;
            left: 130px;
    
            .item {
                font-size: 12px;
                line-height: 30px;
                color: #F0582E;
                height: 30px;
                padding-left: 20px;
            }
    
            .x {
                width: 12px;
            }
        }
    
        .betterTeacherEqual {
            font-size: 18px;
            line-height: 30px;
            left: 130px;
            top: 150px;
        }
    }
}

.facts {
    width: 100%;
    padding: 107px 0 114px;
    position: relative;
    margin-bottom: 102px;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        animation: fadeIn 0.8s forwards;
        animation-delay: 0.4s;
    }

    .factsWrapper {
        width: 1200px;
        margin: 57px auto 0;
        display: flex;
        justify-content: space-between;
        opacity: 0;

        &.visible {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;
        }
    }

    .factItem {
        width: 285px;
        position: relative;
    }

    .factIconContainer{
        width: 100%;
        height: 164px;
        position: relative;
    }

    .factIcon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .factText {
        font-size: 24px;
        line-height: 44px;
        color: #080B0B;
    }

    @media only screen and (max-width: 1300px) {
        .factsWrapper {
            width: 1000px;
            flex-wrap: wrap;
        }

        .factItem {
            width: 50%;
            margin-bottom: 38px;
        }
    }

    @media only screen and (max-width: 1100px) {
        background-color: #FDCF41;
        padding: 57px 0 20px;
        margin-bottom: 60px;

        .factsWrapper {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
            margin-top: 30px;
        }

        .factItem {
            width: 100%;
        }

        .factIconContainer {
            height: 100px;
        }

        .factIcon {
            height: 70px;
        }

        .factText {
            font-style: 13px;
            line-height: 27px;
        }

        .background {
            display: none;
        }
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}