.header {
    position: relative;
    width: 100%;
    height: 100px;
    line-height: 100px;
    z-index: 1001;
    outline: none;

    * {
        outline: none;
    }
}

.clearfix::after{
    content: "";
    clear: both;
    display: block;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    height: 100%;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.logo {
    display: block;
    height: 64px;
    float: left;
    margin-top: 18px;
}