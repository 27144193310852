.menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    background-color: #fff;
    z-index: 1000;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    padding-top: 100px;

    &.opened {
        left: 0;
    }
}

.links {
    width: 100%;
    margin-bottom: 60px;
}

.menuItem {
    display: block;
    text-decoration: none;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 29px;
    color: #080B0B;
    animation: slideUp 0.8s forwards;
    opacity: 0;

    &:nth-child(1) {
        animation-delay: 0.2s;
    }
    
    &:nth-child(2) {
        animation-delay: 0.3s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }

    &:nth-child(4) {
        animation-delay: 0.5s;
    }

    &:nth-child(5) {
        animation-delay: 0.6s;
        margin-bottom: 0;
    }
}

.buttonContainer {
    animation: slideUp 0.8s forwards;
    animation-delay: 0.7s;
    opacity: 0;
}

.switches {
    margin-top: 50px;
    width: 336px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: 400px) {
        width: 100%;
        transform: scale(0.7);
        margin-top: 0;
    }
}

.switchesOuter {
    animation: slideUp 0.8s forwards;
    animation-delay: 0.8s;
    opacity: 0;
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}