.button {
    text-align: center;
    position: relative;
    transition: 0.3s;
    height: 69px;
    margin: 0 auto;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.button .topSvg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s;
}

.button .bottomSvg {
    fill: #DA3F14;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1;
}

.button:active .topSvg {
    transform: translateY(3px);
}

.button:active .text {
    transform: translateY(3px);
}

.button:active .icon {
    transform: translateY(3px);
}

.right {
    float: right;
}

.left {
    float: left;
}

.headerBtn {
    margin-top: 15.5px;

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.text {
    height: 69px;
    width: 100%;
    line-height: 69px;
    font-size: 24px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    outline: none;
    transition: 0.3s;
}

.text img {
    vertical-align: middle;
    margin-right: 10px;
}

/* call button */

.button.call {
    width: 256px;

    @media only screen and (max-width: 650px) {
        margin: 0;
        z-index: -1;

        &.center {
            margin: 0 auto;
        }
    }
}

.button.call .topSvg {
    fill: #F55428;
}

.button.call .bottomSvg {
    fill: #DA3F14;
}

.button.call:hover .topSvg {
    fill: #ED481B;
}

.button.call:hover .bottomSvg {
    fill: #D83C11;
}

/* buy button */

.button.buy {
    width: 256px;
}

.button.buy .topSvg {
    fill: #F55428;
}

.button.buy .bottomSvg {
    fill: #DA3F14;
}

.button.buy:hover .topSvg {
    fill: #ED481B;
}

.button.buy:hover .bottomSvg {
    fill: #D83C11;
}

/* call my boss button */

.button.callMyBoss {
    width: 365px;
    margin: 0;
    margin-top: 30px;

    @media only screen and (max-width: 1100px) {
        margin: 30px auto 0;
    }

    @media only screen and (max-width: 450px) {
        display: none;
    }
}

.button.callMyBoss .topSvg {
    fill: #F55428;
}

.button.callMyBoss .bottomSvg {
    fill: #DA3F14;
}

.button.callMyBoss:hover .topSvg {
    fill: #ED481B;
}

.button.callMyBoss:hover .bottomSvg {
    fill: #D83C11;
}


/* add to calendar */

.button.addToCalendar {
    width: 306px;
    margin: 0;
    margin-top: 30px;
    display: inline-block;

    @media only screen and (max-width: 1300px) {
        margin-bottom: 10px;
        display: block;
    }

    @media only screen and (max-width: 600px) {
        margin-top: 0;
    }

    @media only screen and (max-width: 320px) {
        margin-left: -9px;
    }
}

.button.addToCalendar .topSvg {
    fill: #F55428;
}

.button.addToCalendar .bottomSvg {
    fill: #DA3F14;
}

.button.addToCalendar:hover .topSvg {
    fill: #ED481B;
}

.button.addToCalendar:hover .bottomSvg {
    fill: #D83C11;
}

/* fb event button */

.button.regForEventButton {
    width: 306px;
    display: inline-block;
    margin-left: 24px;

    @media only screen and (max-width: 1300px) {
        margin-left: 0;
        display: block;
    }

    @media only screen and (max-width: 320px) {
        margin-left: -9px;
    }
}

.button.regForEventButton .topSvg {
    fill: #0198FF;
}

.button.regForEventButton .bottomSvg {
    fill: #0289E4;
}

.button.regForEventButton:hover .topSvg {
    fill: #0492F2;
}

.button.regForEventButton:hover .bottomSvg {
    fill: #0686DD;
}

/* messanger button */

.button.messanger {
    width: 277px;

    @media only screen and (max-width: 650px) {
        margin: 0;
        margin-bottom: 10px;
    }
}

.button.messanger .topSvg {
    fill: #0198FF;
}

.button.messanger .bottomSvg {
    fill: #0289E4;
}

.button.messanger:hover .topSvg {
    fill: #0492F2;
}

.button.messanger:hover .bottomSvg {
    fill: #0686DD;
}

/* white messanger button */

.button.whiteMessanger {
    width: 277px;

    @media only screen and (max-width: 650px) {
        margin-bottom: 10px;
    }
}

.button.whiteMessanger .text {
    color: #080B0B;
}

.button.whiteMessanger .topSvg {
    fill: #fff;
}

.button.whiteMessanger .bottomSvg {
    fill: #DBDBDB;
}

.button.whiteMessanger:hover .topSvg {
    fill: #F3F3F3;
}

.button.whiteMessanger:hover .bottomSvg {
    fill: #DBDBDB;
}

/* more button */

.button.more{
    width: 280px;
}

.button.more .topSvg {
    fill: #91D1C5;
}

.button.more .bottomSvg {
    fill: #76BAAD;
}

.button.more:hover .topSvg {
    fill: #83C6B9;
}

.button.more:hover .bottomSvg {
    fill: #6EB5A7;
}

/* facebook */

.button.facebook {
    width: 118px;
    height: 125px;
    position: relative;
    margin: 0;

    @media only screen and (max-width: 600px) {
        transform: scale(0.5);
    }
}

.button.facebook.inFooter {
    transform: scale(0.32);
}

#facebookClipPath {
    display: none;
}

.iconContainer {
    position: absolute;
    top: 0;
    left: 0;
    clip-path: url(#facebookClipPath);
    width: 118px;
    height: 119px;
    z-index: 10;
    // overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.button.facebook .icon {
    position: absolute;
    bottom: 0;
    left: 28px;
    z-index: 9;
    transition: 0.3s;
}

.button.facebook .topSvg {
    fill: #3C5A9A;
}

.button.facebook .bottomSvg {
    fill: #2C4374;
}

.button.facebook:hover .topSvg {
    fill: #335190;
}

.button.facebook:hover .bottomSvg {
    fill: #2C4374;
}

/* youtube */

.button.youtube{
    width: 120px;
    height: 125px;
    position: relative;
    margin: 0;

    @media only screen and (max-width: 600px) {
        transform: scale(0.5);
    }
}

.button.youtube.inFooter {
    transform: scale(0.32);
}

.button.youtube .icon{
    position: absolute;
    top: 39px;
    left: 46px;
    z-index: 10;
    transition: 0.3s;
}

.button.youtube .topSvg {
    fill: #FF5627;
}

.button.youtube .bottomSvg {
    fill: #D74016;
}

.button.youtube:hover .topSvg {
    fill: #ED481B;
}

.button.youtube:hover .bottomSvg {
    fill: #D83C11;
}

/* instagram */

.button.instagram{
    width: 118px;
    height: 122px;
    position: relative;
    margin: 0;

    @media only screen and (max-width: 600px) {
        transform: scale(0.5);
    }

    &:hover {
        .hoverOverlay {
            opacity: 1;
        }
    }
}

.hoverOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.15);
    transition: 0.3s;
    z-index: 20;
}

.button.instagram.inFooter {
    transform: scale(0.32);
}

.button.instagram .icon{
    position: absolute;
    top: 32px;
    left: 33px;
    z-index: 10;
    transition: 0.3s;
}

.button.instagram .bottomSvg {
    fill: #AA3268;
}

.button.instagram:hover .bottomSvg {
    fill: #AA3268;
}

/* telegram */

.button.telegram{
    width: 119px;
    height: 122px;
    position: relative;
    margin: 0;

    @media only screen and (max-width: 600px) {
        transform: scale(0.5);
    }
}

.button.telegram.inFooter {
    transform: scale(0.32);
}

.button.telegram .icon{
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 10;
    transition: 0.3s;
}

.button.telegram .topSvg {
    fill: #2AA2D5;
}

.button.telegram .bottomSvg {
    fill: #2192C2;
}

.button.telegram:hover .topSvg {
    fill: #1B98CD;
}

.button.telegram:hover .bottomSvg {
    fill: #2192C2;
}