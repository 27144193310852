.blog {
    padding-top: 103px;

    @media only screen and (max-width: 1100px) {
        padding-top: 20px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto 80px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.title {
    font-size: 70px;
    line-height: 86px;
    position: relative;
    z-index: 10;
    color: #080B0B;
    margin: 0;

    @media only screen and (max-width: 680px) {
        font-size: 33px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
    }
}

.hero {
    height: 86px;
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 680px) {
        margin-bottom: 0;
        position: relative;
        justify-content: flex-end;
    }
}

.categories {
    margin-bottom: 60px;
    position: relative;
    padding-right: 150px;
    box-sizing: border-box;

    @media only screen and (max-width: 1100px) {
        padding-right: 0;
        display: none;

        .categoriesWrp {
            margin-bottom: 16px;
            overflow: auto;
        }
    }
}

.count {
    position: absolute;
    width: 150px;
    top: 14px;
    right: 0;
    font-size: 18px;
    line-height: 36px;
    text-align: right;
    color: rgba(8, 11, 11, 0.7);

    @media only screen and (max-width: 1100px) {
        position: relative;
        top: 0;
        text-align: left;
        width: auto;
    }
}

.posts {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 1100px) {
        justify-content: space-around;
    }
}