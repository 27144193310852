.product {
    width: 100%;
    box-sizing: border-box;
    padding-left: 521px;
    position: relative;
    min-height: 520px;
    margin-bottom: 100px;
    opacity: 0;

    &:nth-child(odd) {

        padding-left: 0;
        padding-right: 521px;
        
        .photo {
            left: initial;
            right: 0;
        }

        @media only screen and (max-width: 1300px) {
            padding-right: 460px;
        }

        @media only screen and (max-width: 1100px) {
            padding-right: 0;
        }
    }

    &.visible {
        animation: slideUp 1s forwards;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: 460px;
    }

    @media only screen and (max-width: 1100px) {
        padding-left: 0;
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 60px;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
        font-size: 21px;
        line-height: 26px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 17px;

    @media only screen and (max-width: 600px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 414px;

    @media only screen and (max-width: 1100px) {
        position: relative;
        margin-bottom: 20px;
        margin: 0 auto;
        display: block;
    }
}

.price {
    font-size: 70px;
    line-height: 86px;
    color: #91D1C5;
    margin-bottom: 29px;

    span {
        font-size: 40px;
        line-height: 49px;
    }

    @media only screen and (max-width: 1100px) {
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        font-size: 33px;
        line-height: 40px;

        span {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}