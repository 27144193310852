.wrapper {
    width: 760px;
    text-align: center;
    margin: 120px auto;
}

.title {
    font-size: 40px;
    line-height: 60px;
    text-align: center; 
    color: #080B0B;
    margin-bottom: 62px;
}

.text {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: rgba(8, 11, 11, 0.7);

    .green {
        color: #91D1C5;
    }

    .yellow {
        color: #FDCF41;
    }
}