.item {
    height: 100px;
    width: auto;
    position: relative;
    line-height: 100px;
    text-decoration: none;
    font-family: 'monsters';
    display: block;
    float: right;
    font-size: 24px;
    margin-right: 38px;

    @media only screen and (max-width: 1300px) {
        margin-right: 24px;
        font-size: 18px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.text {
    color: #000;
    height: 100px;
    line-height: 100px;
}

.img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    transition: 0.3s;
    transform: translateY(-100%);

    &.monstersLink {
        width: 73px;

        @media only screen and (max-width: 1300px) {
            width: 100%;
        }
    }

    @media only screen and (max-width: 1300px) {
        width: 100%;
    }
}

.item.active img{
    transform: translateY(0);
}

.item:hover img{
    transform: translateY(0);
}