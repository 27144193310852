.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(100px);
    z-index: 1002;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;

    &.visible {
        pointer-events: all;
        opacity: 1;
    }

    @media only screen and (max-width: 890px) {
        display: none;
    }
}

.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000000;
    width: 872px;
    height: 721px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;

    &.modalSuccess {
        height: 544px;

        @media only screen and (max-width: 890px) {
            height: 100%;
        }
    }
    
    &.visible {
        pointer-events: all;
        opacity: 1;
    }

    @media only screen and (max-width: 890px) {
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .modalShape {
            display: none;
        }
    }
}

.closeIcon {
    position: absolute;
    top: 80px;
    right: 100px;
    cursor: pointer;
    opacity: 0.15;
    transition: 0.3s;

    &:hover {
        opacity: 0.35;
    }

    @media only screen and (max-width: 890px) {
        top: 40px;
        right: 40px;
    }

    @media only screen and (max-width: 650px) {
        top: 20px;
        right: 20px;
    }
}

.content {
    width: 590px;
    height: 477px;
    position: absolute;
    left: 144px;
    top: 116px;

    &.success {
        text-align: center;
        height: 299px;

        @media only screen and (max-width: 890px) {
            height: auto;
        }
    }

    @media only screen and (max-width: 890px) {
        width: 600px;
        height: auto;
        top: initial;
        left: initial;
        box-sizing: border-box;
    }

    @media only screen and (max-width: 650px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 20px;

    @media only screen and (max-width: 650px) {
        font-size: 21px;
        line-height: 26px;
        text-align: center;
    }
}

.text {
    font-weight: normal;
    font-size: 30px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);

    @media only screen and (max-width: 890px) {
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 14px;
        line-height: 27px;
        text-align: center;
    }
}

.form {
    width: 565px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    @media only screen and (max-width: 890px) {
        position: relative;
    }

    @media only screen and (max-width: 650px) {
        text-align: center;
        width: 100%;
        z-index: 1000000;
    }
}

.phoneIcon {
    margin-bottom: 26px;
    margin-top: 35px;
}