@font-face {
    font-family: 'monsters';
    src: local('FiraSans'), url(../../../assets/fonts/monsters.ttf) format('truetype');
}

.search {
    height: 65px;
    width: 128px;
    position: relative;
    transition: 0.3s;
    padding-left: 33px;
    box-sizing: border-box;
    z-index: 5;
    background-color: #fff;

    @media only screen and (max-width: 680px) {
        margin-top: 10px;
    }
    

    &.opened {
        width: 316px;
        padding-left: 62px;

        @media only screen and (max-width: 680px) {
            width: 100%;
            box-sizing: border-box;
        }

        .input {
            &::placeholder {
                color: rgba($color: #080B0B, $alpha: 0.2);
            }
        }

        .border {
            opacity: 1;

            @media only screen and (max-width: 680px) {
                width: 100%;
            }
        }

        .searchIcon {
            left: 29px;
        }

        .close {
            pointer-events: all;
            opacity: 0.15;

            &:hover{
                opacity: 0.3;
            }
        }
    }

    form {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
}

.input {
    width: 100%;
    position: relative;
    z-index: 3;
    border: none;
    background: none;
    outline: none;
    color: #080B0B;
    font-family: 'monsters';
    font-size: 24px;
    display: block;
    height: 100%;

    &::placeholder {
        color: #080B0B;
    }
}

.searchIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    z-index: 2;
}

.close {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 26px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    z-index: 4;
    cursor: pointer;
}