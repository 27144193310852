.post {
    width: 1200px;
    margin: 0 auto;
    padding-top: 106px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 40px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.wrapper {
    width: 788px;
    margin: 0 auto;

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

.photo {
    clip-path: url(#postClipPath);
    transform: translate3d(0, 0, 0);
    width: 772px;
    height: 524px;
    margin-bottom: 40px;
    overflow: hidden;

    img {
        width: 100%;
    }

    @media only screen and (max-width: 800px) {
        width: 296px;
        height: 200px;
        margin-bottom: 20px;
        margin-left: -4px;
    }
}

.clipSvg {
    opacity: 0;
    position: absolute;
}

.title {
    font-size: 40px;
    line-height: 49px;  
    color: #080B0B;
    margin-bottom: 22px;

    @media only screen and (max-width: 800px) {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 5px;
    }
}

.categoryContainer {
    height: 69px;
    margin-bottom: 50px;
    justify-content: space-between;
    display: flex;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        height: auto;
        align-items: start;
        margin-bottom: 33px;
    }
}

.date {
    line-height: 69px;
    font-size: 24px;
    text-align: right;
    color: rgba(8, 11, 11, 0.7);

    @media only screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 27px;
        margin-bottom: 11px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);

    p {
        margin-top: 0;
        margin-bottom: 38px;
    }

    a {
        color: #91D1C5;

        &:hover {
            color: #83C6B9;
        }
    }

    img {
        clip-path: url(#postClipPath);
        transform: translate3d(0, 0, 0);
        width: 100%;
        overflow: hidden;
    }

    @media only screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 27px;
    }
}

:global {
    .call_to_action {
        width: 100%;
        height: 306px;
        position: relative;
        margin-bottom: 50px;
        margin-top: 50px;
        padding-top: 75px;
        text-align: center;
        box-sizing: border-box;

        @media only screen and (max-width: 800px) {
            width: calc(100% + 32px);
            left: -16px;
            margin-bottom: 38px;
            margin-top: 38px;
            height: auto;
            background-color: #91D1C5;
            padding-top: 35px;
            padding-bottom: 35px;
        }
    
        .call_to_action_text {
            position: relative;
            z-index: 2;
            color: #fff;
            font-size: 40px;
            line-height: 49px;
            margin-bottom: 30px;
            margin-top: 0;
            display: block;

            @media only screen and (max-width: 800px) {
                font-size: 21px;
                line-height: 26px;
                box-sizing: border-box;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    
        .call_to_action_svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            @media only screen and (max-width: 800px) {
                display: none;
            }
        }
    
        .call_to_action_btn {
            text-align: center;
            position: relative;
            transition: 0.3s;
            height: 69px;
            margin: 0 auto;
            cursor: pointer;
            width: 256px;
            display: block;
            text-decoration: none;
    
            .call_to_action_btn_text {
                height: 69px;
                width: 100%;
                line-height: 69px;
                font-size: 24px;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                outline: none;
                transition: 0.3s;
            }
        }
        
        .call_to_action_btn .call_to_action_topSvg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transition: 0.3s;
            fill: #F55428;
        }
        
        .call_to_action_btn .call_to_action_bottomSvg {
            fill: #DA3F14;
            position: absolute;
            top: 5px;
            left: 0;
            z-index: 1;
        }
        
        .call_to_action_btn:active .call_to_action_topSvg {
            transform: translateY(3px);
        }
        
        .call_to_action_btn:active .call_to_action_btn_text {
            transform: translateY(3px);
        }
        
        .call_to_action_btn:hover .call_to_action_topSvg {
            fill: #ED481B;
        }
        
        .call_to_action_btn:hover .call_to_action_bottomSvg {
            fill: #D83C11;
        }
    }
}

.authorContainer {
    height: 116px;
    line-height: 116px;
    margin-bottom: 110px;
    
    &::after {
        content: '';
        display: block;
        clear: both;
    }

    .author {
        clip-path: url(#authorClipPath);
        width: 119px;
        height: 100%;
        float: left;
        transform: translate3d(0, 0, 0);
        text-align: center;

        img {
            width: auto;
            height: 100%;
        }
    }
    
    .authorName {
        font-size: 24px;
        line-height: 116px;
        color: #080B0B;
        margin-left: 32px;
        float: left;
    }

    .social {
        font-size: 24px;
        line-height: 116px;
        color: rgba(8, 11, 11, 0.7);
        float: right
    }

    .socialButtons {
        float: right;
        width: 100px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 116px;
    }

    @media only screen and (max-width: 800px) {
        margin-bottom: 35px;

        .social {
            display: none;
        }

        .socialButtons {
            display: none;
        }

        .author {
            transform: scale(0.7);
            margin-left: -20px;
        }

        .authorName {
            font-size: 16px;
            margin-left: -10px;
        }
    }
}

.shareMobile {
    display: none;

    @media only screen and (max-width: 800px) {
        display: block;
        background-color: #F4F4F4;
        padding-top: 50px;
        padding-bottom: 72px;
        width: calc(100% + 32px);
        margin-left: -16px;
        text-align: center;
        margin-bottom: 60px;

        .socialMobile {
            font-size: 16px;
            line-height: 44px;
            text-align: center;
            color: rgba(8, 11, 11, 0.7);
        }

        .socialButtonsMobile {
            width: 200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                transform: scale(0.7);
            }
        }
    }
}

.popularTitle {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 50px;

    @media only screen and (max-width: 800px) {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 35px;
    }
}

.popularPosts {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
        // justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
}