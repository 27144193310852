.agendaItem {
    width: 1232px;
    height: 136px;
    margin-left: -16px;
    position: relative;
    box-sizing: border-box;
    padding-top: 46px;
    padding-left: 155px;
    padding-right: 48px;
    display: block;
    text-decoration: none;

    &.soldOut {
        * {
            color: #D8D8D8;
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    @media only screen and (max-width: 1300px) {
        width: 1032px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        height: auto;
        padding: 56px 48px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @media only screen and (max-width: 1300px) {
        width: 1032px;
        height: 136px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        height: 100%;
    }
}

.bottles {
    height: 37px;
    width: 74px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 46px;
    left: 56px;
    z-index: 1;

    @media only screen and (max-width: 1100px) {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 16px;
    }
}

.title {
    font-size: 24px;
    line-height: 36px;
    color: #080B0B;
    float: left;

    @media only screen and (max-width: 1300px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 1100px) {
        float: none;
        margin-bottom: 16px;
        box-sizing: border-box;
        margin-top: 4px;

    }
}

.option {
    float: right;
    margin-left: 36px;
    color: #080B0B;

    .icon {
        margin-right: 14px;
        height: 36px;
        display: inline-block;
        vertical-align: top;
    }

    span {
        font-size: 24px;
        line-height: 36px;
        display: inline-block;
        vertical-align: top;
        color: #080B0B;
    }

    @media only screen and (max-width: 1300px) {
        span {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 1100px) {
        float: none;
        margin-bottom: 16px;
        margin-left: 0;

        span {
            font-size: 18px;
        }
    }
}

.soldOutIcon {
    position: absolute;
    top: 0;
    bottom: 10px;
    right: 232px;
    margin: auto;
}