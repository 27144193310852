.category {
    display: inline-block;
    height: 69px;
    line-height: 69px;
    width: auto;
    position: relative;
    padding: 0 27px;
    font-size: 24px;
    line-height: 69px;
    text-align: center;
    color: #080B0B;
    text-decoration: none;
    margin-right: 24px;

    &.active {
        color: #fff;
    }
}

.border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
