.footer {
    background-color: #212222;
    
    &.inCallToAction {
        margin-top: -20px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 64px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
    }

    @media only screen and (max-width: 400px) {
        padding-bottom: 30px;
    }
}

.footerTopContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 78px;

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
            margin-bottom: 63px;
        }
    }
}

.footerLinks {
    width: 364px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.link {
    display: block;
    color: #fff;
    font-size: 24px;
    line-height: 29px;
    text-decoration: none;  
    margin-bottom: 18px;

    @media only screen and (max-width: 1100px) {
        text-align: center;
    }
}

.foooterBottomContainer {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }
}

.social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 444px;
    height: 40px;
    
    @media only screen and (max-width: 1100px) {
        width: 100%;
    }
}

.socialText {
    color: #fff;
    opacity: 0.5;
    font-size: 24px;

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.socialButtons {
    width: 196px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @media only screen and (max-width: 1100px) {
        transform: translateX(-10px);
        margin-bottom: 40px;
    }
}

.switches {
    width: 336px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 400px) {
        width: 100%;
        transform: scale(0.7);
    }
}
