.calendar {
    padding-top: 103px;
    
    @media only screen and (max-width: 1100px) {
        overflow: hidden;
        padding-top: 36px;
    }
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    min-height: 1000px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.events {
    margin-top: 93px;
    margin-bottom: 106px;
    opacity: 0;
    animation: slideUp 1s forwards;
    animation-delay: 0.4s;

    @media only screen and (max-width: 1100px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 36px;
    }

    @media only screen and (max-width: 450px) {
        justify-content: center;
    }
}

.agenda {
    margin-top: 35px;
    margin-bottom: 114px;
    opacity: 0;

    &.visible {
        animation: slideUp 1s forwards;
        animation-delay: 0.4s;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        margin-top: 16px;
        margin-bottom: 36px;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}