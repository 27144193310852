.wrapper {
    padding: 42px 0 118px;
    width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 20px;
    }
}

.method {
    padding-left: 300px;
    position: relative;
    margin-bottom: 65px;

    @media only screen and (max-width: 1300px) {
        padding-left: 400px;
    }

    @media only screen and (max-width: 1100px) {
        padding-left: 0;
        padding-bottom: 300px;
    }

    @media only screen and (max-width: 650px) {
        margin-bottom: 0 !important;
        padding-bottom: 270px;
    }

    @media only screen and (max-width: 400px) {
        &:first-child {
            .image {
                margin-right: -40px;
            }
        }
    }

    &.visible {
        .num {
            animation: slideUp 0.8s forwards;
        }
        .title {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.2s;
        }

        .description {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;
        }
    }
}

.methodEven {
    padding-right: 300px;
    position: relative;
    text-align: right;
    margin-bottom: 65px;

    @media only screen and (max-width: 1300px) {
        padding-right: 400px;
    }

    @media only screen and (max-width: 1100px) {
        padding-right: 0;
        padding-bottom: 350px;
        text-align: left;
    }

    @media only screen and (max-width: 650px) {
        margin-bottom: 0;
        padding-bottom: 270px;

        &:last-child{
            padding-bottom: 300px;
        }
    }

    &.visible {
        .num {
            animation: slideUp 0.8s forwards;
        }
        .title {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.2s;
        }

        .description {
            animation: slideUp 0.8s forwards;
            animation-delay: 0.4s;
        }
    }
}

.image {
    height: 300px;
    width: 285px;
    position: absolute;
    top: 0;

    @media only screen and (max-width: 1100px) {
        top: initial;
        bottom: 0;
    }
}

.method .image {
    left: 0;

    @media only screen and (max-width: 1300px) {
        left: 70px;
    }

    @media only screen and (max-width: 1100px) {
        left: 0;
        right: 0;
        margin: auto;
    }

    @media only screen and (max-width: 650px) {
        transform: scale(0.8);
    }
}

.methodEven .image {
    right: 0;

    @media only screen and (max-width: 1300px) {
        right: 70px;
    }

    @media only screen and (max-width: 1100px) {
        left: 0;
        right: 0;
        margin: auto;
    }

    @media only screen and (max-width: 650px) {
        transform: scale(0.8);
    }
}

.info {
    padding-top: 5px;

}

.num{
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 4px;
    opacity: 0;

    @media only screen and (max-width: 650px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.num.green {
    color: #91D1C5;
}

.num.red {
    color: #F55428;
}

.num.yellow {
    color: #FDCF41;
}

.title {
    font-size: 40px;
    line-height: 49px;
    color: #080B0B;
    margin-bottom: 21px;
    opacity: 0;

    @media only screen and (max-width: 650px) {
        font-size: 21px;
        line-height: 26px;
    }
}

.description {
    max-width: 586px;
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    display: inline-block;
    opacity: 0;
    
    p {
        margin-top: 0;
    }

    @media only screen and (max-width: 650px) {
        font-size: 13px;
        line-height: 27px;
    }
}

/* lines */

.firstPath {
    position: absolute;
    top: 300px;
    left: 210px;

    @media only screen and (max-width: 1300px) {
        transform: scale(0.65);
        top: 290px;
        left: 120px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.secondPath {
    position: absolute;
    top: 290px;
    right: 200px;

    @media only screen and (max-width: 1300px) {
        transform: scale(0.65);
        top: 280px;
        right: 70px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.thirdPath {
    position: absolute;
    top: 278px;
    left: 206px;

    @media only screen and (max-width: 1300px) {
        transform: scale(0.65);
        top: 268px;
        left: 146px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.fourthPath {
    position: absolute;
    top: 335px;
    right: 245px;

    @media only screen and (max-width: 1300px) {
        transform: scale(0.65);
        top: 345px;
        right: 95px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.fifthPath {
    position: absolute;
    top: 308px;
    left: 208px;

    @media only screen and (max-width: 1300px) {
        transform: scale(0.65);
        top: 308px;
        left: 118px;
    }

    @media only screen and (max-width: 1100px) {
        display: none;
    }
}


// jim carrey

.jimCarrey {
    height: 240px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
}

.jimEllipse {
    position: absolute;
    right: 18px;
    bottom: 2px;
    z-index: 2;
    opacity: 0;
}

.jimBarbell {
    position: absolute;
    top: 36px;
    left: -102px;
    z-index: 2;
    opacity: 0;
}

.jimBandana {
    position: absolute;
    top: 25px;
    left: 108px;
    z-index: 2;
    opacity: 0;
}

.jimText {
    width: 214px;
    height: 44px;
    color: #fff;
    z-index: 3;
    position: absolute;
    bottom: 11px;
    right: 64px;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    opacity: 0;
}

// chameleon

.chameleon {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 20px;
    width: 272px;
    opacity: 0;
}

.chameleonHand {
    position: absolute;
    z-index: 3;
    width: 38px;
    top: 157px;
    left: 103px;
    opacity: 0;
}

.chameleonSkull {
    position: absolute;
    z-index: 2;
    left: 54px;
    top: 153px;
    opacity: 0;
}

.chameleonStick {
    position: absolute;
    z-index: 2;
    bottom: 83px;
    right: -10px;
    opacity: 0;
}

.chameleonCloud {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
}

.chameleonCloudText {
    color: #fff;
    width: 200px;
    height: 44px;
    position: absolute;
    z-index: 3;
    top: 22px;
    left: 0;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
    opacity: 0;
}

// remote

.remoteBg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.remote {
    position: absolute;
    z-index: 2;
    width: 298px;
    opacity: 0;
}

.remoteWords {

    .word {
        position: absolute;
        font-size: 30px;
        line-height: 44px;
        text-align: center;
        color: #91D1C5;
        z-index: 4;
        opacity: 0;
        
        &:nth-child(1) {
            top: 8px;
            left: 82px;
            transform: rotate(34.18deg);
        }
        
        &:nth-child(2) {
            left: 78px;
            top: 94px;
            transform: rotate(-11.61deg);
        }

        &:nth-child(3) {
            transform: rotate(-29.41deg);
            right: 0;
            top: 62px;
        }

        &:nth-child(4) {
            transform: rotate(39.04deg);
            right: 20px;
            top: 130px;
        }
    }
}

// pizza

.pizza {
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 10px;
    width: 280px;
    opacity: 0;
}

.pizzaFlag {
    position: absolute;
    z-index: 3;
    top: 22px;
    left: 140px;
    opacity: 0;
}

.pizzaDrips {
    position: absolute;
    z-index: 1;
    top: 150px;
    left: 13px;
    opacity: 0;
}

.pizzaHam1 {
    position: absolute;
    z-index: 3;
    top: 151px;
    left: 65px;
    opacity: 0;
}

.pizzaHam2 {
    position: absolute;
    z-index: 3;
    top: 178px;
    left: 84px;
    opacity: 0;
}

.pizzaHam3 {
    position: absolute;
    z-index: 3;
    top: 178px;
    left: 174px;
    opacity: 0;
}

.pizzaHam4 {
    position: absolute;
    z-index: 3;
    top: 150px;
    left: 204px;
    opacity: 0;
}

// bike 

.bike {
    position: relative;
    z-index: 2;
    width: 285px;
    opacity: 0;
}

.bikeBg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.bikeCrown {
    position: absolute;
    z-index: 3;
    top: 73px;
    left: 33px;
    opacity: 0;
}

.bikeCloud {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 38px;
    opacity: 0;
}

.bikeText {
    height: 44px;
    font-size: 20px;
    line-height: 44px;
    width: 166px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 20px;
    z-index: 4;
    right: 38px;
    opacity: 0;
}

// salt 

.salt {
    position: absolute;
    z-index: 2;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
    width: 148px;
    opacity: 0;
}

.saltBg {
    position: absolute;
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 30px;
    opacity: 0;
}

.saltLabel {
    position: absolute;
    z-index: 3;
    margin: auto;
    left: 1px;
    right: 0;
    bottom: 120px;
    opacity: 0;
}

.saltText {
    position: absolute;
    z-index: 4;
    margin: auto;
    left: 1px;
    right: 0;
    bottom: 120px;
    height: 78px;
    width: 135px;
    line-height: 78px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    opacity: 0;
}

// animations

.visible {
    .image {
        .jimEllipse {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.4s;
        }

        .jimCarrey {
            animation: slideUpLong 0.8s forwards;
        }

        .jimBarbell {
            animation: rotateIn 0.6s forwards;
            animation-delay: 0.4s;
        }

        .jimBandana {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.4s;
        }

        .jimText {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.6s;
        }

        .chameleon {
            animation: slideUpLong 0.8s forwards;
        }

        .chameleonHand {
            animation: slideUpLong 0.8s forwards;
        }

        .chameleonSkull {
            animation: rotateInLeft 0.6s forwards;
            animation-delay: 0.4s;
        }

        .chameleonStick {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.4s;
        }

        .chameleonCloud {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.4s;
        }

        .chameleonCloudText {
            animation: scaleOut 0.8s forwards;
            animation-delay: 0.6s;
        }

        .remoteBg {
            animation: fadeInScale 1.2s forwards;
        }
        
        .remote {
            animation: slideDown 0.6s forwards;
            animation-delay: 0.2s;
        }

        .remoteWords {
            .word {
                animation: fadeIn 0.8s forwards;
            
                &:nth-child(1) {
                    animation-delay: 0.4s;
                }

                &:nth-child(2) {
                    animation-delay: 0.6s;
                }

                &:nth-child(3) {
                    animation-delay: 0.8s;
                }

                &:nth-child(4) {
                    animation-delay: 1s;
                }
            }
        }

        .pizza {
            animation: slideDown 0.6s forwards;
        }

        .pizzaFlag {
            animation: slideDown 0.6s forwards;
            animation-delay: 0.4s;
        }

        .pizzaDrips {
            animation: dripsDown 1.2s forwards;
            animation-delay: 0.6s;
        }

        .pizzaHam1 {
            animation: fadeIn 0.8s forwards;
            animation-delay: 0.4s;
        }

        .pizzaHam2 {
            animation: fadeIn 0.8s forwards;
            animation-delay: 0.6s;
        }

        .pizzaHam3 {
            animation: fadeIn 0.8s forwards;
            animation-delay: 0.8s;
        }

        .pizzaHam4 {
            animation: fadeIn 0.8s forwards;
            animation-delay: 1s;
        }

        .bikeBg {
            animation: fadeInScale 1.2s forwards;
        }

        .bike {
            animation: bikeAppear 0.6s forwards;
            animation-delay: 0.4s;
        }

        .bikeCrown {
            animation: crownAppear 0.4s forwards;
            animation-delay: 1s;
        }

        .bikeCloud {
            animation: slideDown 0.4s forwards;
            animation-delay: 1s;
        }

        .bikeText {
            animation: scaleOut 0.8s forwards;
            animation-delay: 1.2s;
        }

        .saltBg {
            animation: fadeInScale 1.2s forwards;
        }

        .salt {
            animation: slideDown 0.8s forwards;
            animation-delay: 0.4s;
        }

        .saltLabel {
            animation: slideDown 0.8s forwards;
            animation-delay: 0.4s;
        }

        .saltText {
            animation: slideDown 0.8s forwards;
            animation-delay: 0.4s;
        }
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes slideUpLong{
    0%{
        opacity: 0;
        transform: translateY(40px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes rotateIn{
    0%{
        opacity: 0;
        transform: rotate(15deg)
    }
    100%{
        opacity: 1;
        transform: rotate(0deg)
    }
}

@keyframes rotateInLeft{
    0%{
        opacity: 0;
        transform: rotate(-15deg)
    }
    100%{
        opacity: 1;
        transform: rotate(0deg)
    }
}

@keyframes slideDown{
    0%{
        opacity: 0;
        transform: translateY(-20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes scaleOut{
    0%{
        opacity: 0;
        transform: scale(1.1)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes dripsDown{
    0%{
        opacity: 0;
        height: 0px;
    }
    35%{
        opacity: 0;
    }
    100%{
        opacity: 1;
        height: 169px;
    }
}

@keyframes bikeAppear{
    0%{
        opacity: 0;
        transform: translateX(-200px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes crownAppear{
    0%{
        opacity: 0;
        transform: translate(-20px, -20px);
    }
    100%{
        opacity: 1;
        transform: translate(0, 0);
    }
}