.svg {
    position: absolute;
    opacity: 0;
}

.post {
    position: relative;
    width: 360px;
    height: 514px;
    display: block;
    text-decoration: none;
    margin-right: 60px;
    margin-bottom: 60px;
    transition: 0.3s;
    // overflow: hidden;

    &:nth-child(3n+3) {
        margin-right: 0;
    }

    &:hover {
        transform: translateY(-10px);

        .title {
            color: #F55428;
        }
    }

    @media only screen and (max-width: 1300px) {
        width: 300px;
        height: 430px;
        margin-right: 53px;
        margin-bottom: 53px;
    }

    @media only screen and (max-width: 1100px) {
        margin-right: 0;
    }
}

.shadow {
    position: absolute;
    top: -11px;
    left: -16px;

    @media only screen and (max-width: 1300px) {
        left: -10px;
    }
}

.postWrapper {
    width: 360px;
    height: 514px;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 1300px) {
        width: 298px;
        height: 430px;
    }
}

.postInnerContainer {
    position: absolute;
    top: -11px;
    left: -16px;
    width: 390px;
    height: 544px;
    clip-path: url(#clipPath);
    transform: translate3d(0, 0, 0);

    @media only screen and (max-width: 1300px) {
        width: 298px;
        height: 430px;
        left: 2px;
        top: 0;
    }
}

.postInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 330px;
    padding-left: 56px;
    padding-right: 56px;
    transform: translate3d(0, 0, 0);

    @media only screen and (max-width: 1300px) {
        padding-top: 262px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.photo {
    height: 300px;
    position: absolute;
    width: auto;
    top: 0;
    left: -9999px;
    right: -9999px;
    margin: auto;


    @media only screen and (max-width: 1300px) {
        height: 250px;
    }
}

.info {
    max-height: 154px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 1300px) {
        max-height: 124px;
    }
}

.title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 7px;
    color: #080B0B;
    transition: 0.3s;

    @media only screen and (max-width: 1300px) {
        font-size: 21px;
        line-height: 29px;
    }
}

.text {
    font-size: 18px;
    line-height: 32px;
    color: rgba(8, 11, 11, 0.7);

    @media only screen and (max-width: 1300px) {
        font-size: 21px;
        line-height: 29px;
    }
}