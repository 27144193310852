.hero {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        flex-direction: column-reverse;
        text-align: center;
        margin-bottom: 50px;
    }
}

.image {
    width: 560px;
    height: 400px;
    position: relative;

    .background {
        position: absolute;
        bottom: -170px;
        opacity: 0;
        left: 0;
        animation: fadeInScale 0.8s forwards;
    }

    .stand {
        position: absolute;
        width: 495px;
        top: 55px;
        margin: auto;
        left: 0;
        right: 0;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 0.4s;
    }

    .bigGlass {
        position: absolute;
        right: 85px;
        top: 0;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 0.8s;
    }

    .smallGlass {
        position: absolute;
        top: 12px;
        right: 220px;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 0.9s;
    }

    .rightBottle {
        position: absolute;
        z-index: 2;
        bottom: 125px;
        right: 115px;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 0.8s;
    }
    
    .middleBottle {
        position: absolute;
        z-index: 2;
        bottom: 125px;
        right: 190px;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 0.9s;
    }

    .leftBottle {
        position: absolute;
        z-index: 2;
        bottom: 120px;
        right: 390px;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 1s;
    }

    .skull {
        position: absolute;
        z-index: 2;
        bottom: 120px;
        right: 300px;
        opacity: 0;
        animation: slideDown 0.6s forwards;
        animation-delay: 1.1s;
    }

    @media only screen and (max-width: 1300px) {
        transform: scale(0.75);
        margin-left: -60px;
    }

    @media only screen and (max-width: 1100px) {
        margin-left: 0;
    }

    @media only screen and (max-width: 450px) {
        transform: scale(0.55);
    }
}

.info {
    width: 590px;

    @media only screen and (max-width: 1300px) {
        width: 480px;
        transform: translateX(-20px);
    }

    @media only screen and (max-width: 1100px) {
        transform: translateX(0);
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.title {
    margin-bottom: 21px;
    font-size: 70px;
    line-height: 86px;
    color: #080B0B;
    opacity: 0;
    animation: slideUp 0.8s forwards;
    animation-delay: 0.4s;
    margin-top: 0;

    @media only screen and (max-width: 1300px) {
        font-size: 60px;
        line-height: 76px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 50px;
        line-height: 61px;
        text-align: center;
    }

    @media only screen and (max-width: 450px) {
        font-size: 33px;
        line-height: 40px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);
    opacity: 0;
    animation: slideUp 0.8s forwards;
    animation-delay: 0.6s;

    @media only screen and (max-width: 1300px) {
        font-size: 22px;
        line-height: 42px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 20px;
        line-height: 40px;
        text-align: center;
    }

    @media only screen and (max-width: 450px) {
        font-size: 15px;
        line-height: 30px;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInScale{
    0%{
        opacity: 0;
        transform: scale(0.9)
    }
    100%{
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes slideDown{
    0%{
        opacity: 0;
        transform: translateY(-20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}