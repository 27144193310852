.button {
    position: relative;
    height: 85px;
    width: 85px;
    cursor: pointer;

    &:hover {
        .topSvg {
            path {
                fill: #FFC71C;
            }
        }
    }
}

.button:active {
    .topSvg {
        transform: translateY(3px);
    }
    
    .arrow {
        transform: translateY(3px);
    }
}

.topSvg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s;

    path {
        fill: #FDCF41;
        transition: 0.3s;
    }
}

.bottomSvg {
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 1;

    path {
        fill: #E8BB30;
    }
}

.arrow {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 5px;
    z-index: 3;
    transition: 0.3s;
}