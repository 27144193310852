.event {
    width: 1200px;
    height: 323px;
    margin-bottom: 60px;
    display: block;
    position: relative;

    &:nth-child(3n+1) {
        .firstMonster {
            display: block;
        }
    }

    &:nth-child(3n+2) {
        .secondMonster {
            display: block;
        }
    }

    &:nth-child(3n+3) {
        .thirdMonster {
            display: block;
        }
    }

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        width: 320px;
        height: 668px;
        margin-bottom: 36px;
    }
}

.shadow {
    margin-left: -16px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 1100px) {
        margin-left: 0;
    }
}

.eventWrapperOuter {
    position: absolute;
    width: 1232px;
    height: 323px;
    top: 0;
    margin-left: -16px;
    z-index: 2;
    clip-path: url(#eventContainerSvgPath);
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;

    @media only screen and (max-width: 1300px) {
        width: 1032px;
        clip-path: url(#eventContainerSvgPathSmall);
    }

    @media only screen and (max-width: 1100px) {
        width: 320px;
        height: 668px;
        clip-path: url(#eventContainerSvgPathMobile);
        margin-left: 0;
    }
}

.eventWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    padding-left: 426px;
    padding-top: 80px;
    padding-right: 40px;

    @media only screen and (max-width: 1300px) {
        padding-left: 416px;
        padding-top: 70px;
    }

    @media only screen and (max-width: 1100px) {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 276px;
    }
}

.firstMonster {
    position: absolute;
    left: 640px;
    top: 10px;
    width: 130px;
    transform: rotate(2deg);
    display: none;
}

.secondMonster {
    position: absolute;
    left: 820px;
    top: 10px;
    width: 150px;
    transform: rotate(2deg);
    display: none;

    @media only screen and (max-width: 1300px) {
        top: 21px;
        left: 720px;
    }
}

.thirdMonster {
    position: absolute;
    right: 14px;
    top: 30px;
    width: 181px;
    transform: rotate(20deg);
    display: none;
}

.svgClip {
    opacity: 0;
    position: absolute;
}

.photo {
    width: 404px;
    height: 303px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    clip-path: url(#eventPhotoSvgPath);
    transform: translate3d(0, 0, 0);
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: -9999px;
        right: -9999px;
        margin: auto;
        height: 100%;
        width: auto;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        height: 256px;
        clip-path: none;
    }
}

.title {
    font-size: 24px;
    line-height: 29px;
    color: #080B0B;
    margin-bottom: 7px;

    @media only screen and (max-width: 1300px) {
        width: 560px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        font-size: 21px;
    }
}

.description {
    font-size: 18px;
    line-height: 36px;
    color: rgba(8, 11, 11, 0.7);
    margin-bottom: 6px;

    @media only screen and (max-width: 1300px) {
        width: 560px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 16px;
    }
}

.optionsContainer {
    min-height: 44px;
    width: 720px;
    font-size: 0;

    @media only screen and (max-width: 1300px) {
        width: 560px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
    }
}

.option {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;

    .icon {
        vertical-align: middle;
        margin-right: 10px;
        height: 36px;
        width: 36px;
        display: inline-block;
        position: relative;

        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 100%;
        }
    }

    span {
        font-size: 18px;
        line-height: 44px;
        color: #91D1C5;
        vertical-align: middle;
        display: inline-block;
    }

    @media only screen and (max-width: 1100px) {
        margin-bottom: 16px;
        position: relative;
        padding-left: 46px;
        min-height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 36px;
        }

        span {
            font-size: 14px;
            line-height: 22px;
        }
    }
}