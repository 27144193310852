.container {
    width: 100%;
    padding: 75px 0 85px;
    position: relative;

    @media only screen and (max-width: 1100px) {
        background-color: #91D1C5;
        
        .background {
            display: none;
        }
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.subtitle {
    margin-top: 6px;
    margin-bottom: 38px;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: #080B0B;
    position: relative;
    z-index: 6;
    opacity: 0;

    @media only screen and (max-width: 650px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.buttons {
    width: 560px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 6;
    margin: 0 auto;
    align-items: center;
    opacity: 0;

    @media only screen and (max-width: 650px) {
        flex-direction: column;
        width: 100%;
    }
}

.visible {
    .subtitle {
        animation: slideUp 0.8s forwards;
        animation-delay: 0.4s;
    }

    .buttons {
        animation: slideUp 0.8s forwards;
        animation-delay: 0.8s;
    }
}

@keyframes slideUp{
    0%{
        opacity: 0;
        transform: translateY(20px)
    }
    100%{
        opacity: 1;
        transform: translateX(0)
    }
}