.services {
    padding-top: 120px;

    @media only screen and (max-width: 1100px) {
        padding-top: 40px;
    }
}

.otherServices {
    padding: 100px 0;
}