.page {
    width: 1200px;
    margin: 0 auto;
    padding-top: 106px;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 40px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.wrapper {
    width: 788px;
    margin: 0 auto;

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

.title {
    font-size: 40px;
    line-height: 49px;  
    color: #080B0B;
    margin-bottom: 22px;

    @media only screen and (max-width: 800px) {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 5px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);

    p {
        margin-top: 0;
        margin-bottom: 38px;
    }

    a {
        color: #91D1C5;

        &:hover {
            color: #83C6B9;
        }
    }

    img {
        transform: translate3d(0, 0, 0);
        width: 100%;
    }

    @media only screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 27px;
    }
}