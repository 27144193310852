.vacancy {
    width: 1200px;
    margin: 0 auto;
    padding: 106px 0;

    @media only screen and (max-width: 1300px) {
        width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 40px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
    }
}

.wrapper {
    width: 788px;
    margin: 0 auto;

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

.photo {
    clip-path: url(#vacancyClipPath);
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 520px;
    overflow: hidden;
    background-color: #FDCF41;
    position: relative;
    margin-bottom: 56px;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    @media only screen and (max-width: 800px) {
        width: 296px;
        height: 200px;
        margin-bottom: 20px;
        margin-left: -4px;
    }
}

#vacancyClipPath {
    opacity: 0;
    position: absolute;
}

.title {
    font-size: 40px;
    line-height: 49px;  
    color: #080B0B;
    margin-bottom: 44px;

    @media only screen and (max-width: 800px) {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 5px;
    }
}

.text {
    font-size: 24px;
    line-height: 44px;
    color: rgba(8, 11, 11, 0.7);

    p {
        margin-top: 0;
        margin-bottom: 38px;
    }

    a {
        color: #91D1C5;

        &:hover {
            color: #83C6B9;
        }
    }

    img {
        clip-path: url(#postClipPath);
        transform: translate3d(0, 0, 0);
        width: 100%;
        overflow: hidden;
    }

    @media only screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 27px;
    }
}

.authorContainer {
    height: 116px;
    line-height: 116px;
    
    &::after {
        content: '';
        display: block;
        clear: both;
    }

    .date {
        font-size: 24px;
        line-height: 116px;
        color: rgba(8, 11, 11, 0.7);
        float: left;
    }    

    .social {
        font-size: 24px;
        line-height: 116px;
        color: rgba(8, 11, 11, 0.7);
        float: right
    }

    .socialButtons {
        float: right;
        width: 100px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 116px;
    }

    @media only screen and (max-width: 800px) {
        display: none;
    }
}

.shareMobile {
    display: none;

    @media only screen and (max-width: 800px) {
        display: block;
        background-color: #F4F4F4;
        padding-top: 50px;
        padding-bottom: 72px;
        width: calc(100% + 32px);
        margin-left: -16px;
        text-align: center;
        margin-bottom: 60px;

        .socialMobile {
            font-size: 16px;
            line-height: 44px;
            text-align: center;
            color: rgba(8, 11, 11, 0.7);
        }

        .socialButtonsMobile {
            width: 200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                transform: scale(0.7);
            }
        }
    }
}

.dateMobile {
    display: none;

    @media only screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 27px;
        color: rgba(8, 11, 11, 0.7);
        margin-bottom: 11px;
        display: block;
    }
}